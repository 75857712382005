import {
  useState,
  useEffect
} from 'react'
import { 
  ModalBody, 
  ModalFooter,
  Row,
  Col
} from 'react-bootstrap'
import { 
  IoAddOutline 
} from 'react-icons/io5'
import {
  Formik
} from 'formik'
import * as Yup from 'yup'
import {
  DataStatus,
  Modal,
  ActionButton,
  SelectSearch,
  Table, 
  Td, 
  TdFixed, 
  Th,
  ThFixed,
  InfoItemHorizontal,
  DatePicker,
  Input,
  PopUpAlert
} from 'components'
import {
  DateConvert, 
  RupiahConvert
} from 'utilities'

const TabBahanSection = ({selectedPabrik, dataBahan, dataPermintaanBahan, setDataBahan, setDataPermintaanBahan, dataGudang, isGudangLoading}) => {
  const [modalPermintaanConfig, setModalPermintaanConfig] = useState({
    show: false,
    data: {}
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    type: '',
    title: '',
    text: '',
    hide: () => setAlertConfig({...alertConfig, show: false})
  })
  
  const getDropdownGudang = (idItem) => {
    let newDataDropdown = [];

    dataGudang.filter(val => parseInt(idItem) === parseInt(val.id_item) && val.stok > 0).map((val) => {
      const gudangData = {
        value: val.id_gudang,
        label: val.nama_gudang,
        stok: val.stok,
      };

      newDataDropdown.push(gudangData);
    });

    return newDataDropdown;
  }

  const onGudangChangeHandler = (gudang, index, bahan) => {
    const idGudang =  gudang.value
    const namaGudang = gudang.label
    const stok = parseFloat(gudang.stok)
    const cekStatus = parseFloat(gudang.stok) > parseFloat(bahan.qty_analisa) ? true : false 

    const newDataBahan = dataBahan.map((val, i) => {
      if (index === i) {
        return {
          ...bahan,
          id_gudang: idGudang,
          nama_gudang: namaGudang,
          stok: stok,
          status: cekStatus ? 'OK' : 'Qty. kurang',
        }
      }

      return val
    })

    setDataBahan(newDataBahan)
  }

  const getTotal = (qty, harga) => {
    const parseQty = qty ? parseFloat(qty) : 0
    const parseHarga = harga ? parseInt(harga) : 0

    return parseInt(parseHarga * parseQty)
  }

  const getGrandTotal = () => dataBahan.reduce((prev, current) => {
    const total = getTotal(current.qty_analisa, current.harga)

    return prev + total
  }, 0)
  
  useEffect(() => {
    getDropdownGudang()
  }, [selectedPabrik])

  return (
    <>  
      {/* Table Ingredient */}
       <div>
        <div className="px-1 pb-2">
          <b>List Ingredient</b>
        </div>
        <Table>
          <thead className="bg-light">
            <tr>
              <ThFixed>No.</ThFixed>
              <Th noPadding width={70}>Kode Bahan</Th>
              <Th>Item Bahan</Th>
              <Th noPadding width={70}>Qty. Analisa</Th>
              <Th width={175}>Gudang Produksi</Th>
              <Th width={50}>Stok</Th>
              <Th width={100}>Qty. Selisih</Th>
              {/* <Th width={100}>Status</Th> */}
              <Th width={150}>Harga Satuan</Th>
              <Th width={150}>Sub Total</Th>
            </tr>
          </thead>
          <tbody>
            {dataBahan && dataBahan.length > 0
              ? dataBahan.map((val, index) => (
                <tr key={index}>
                  <Td textCenter>{index + 1}</Td>
                  <Td>{val.kode_item}</Td>
                  <Td>{val.nama_item}</Td>
                  <Td textRight>{val.qty_analisa} {val.kode_satuan}</Td>
                  <Td noPadding>
                    <SelectSearch
                      noMargin
                      placeholder="Pilih gudang"
                      loading={isGudangLoading}
                      defaultValue={getDropdownGudang(val.id_item).find(find => find.value === val.id_gudang) ?? ''}
                      option={getDropdownGudang(val.id_item)}
                      onChange={e => onGudangChangeHandler(e, index, val)}
                    />
                  </Td>
                  <Td textRight>{val.stok ?? 0} {val.kode_satuan}</Td>
                  <Td textRight>{val.stok - val.qty_analisa} {val.kode_satuan}</Td>
                  {/* <Td>{val.status}</Td> */}
                  <Td textRight>{RupiahConvert(val.harga.toString()).detail}</Td>
                  <Td textRight>{RupiahConvert(getTotal(val.qty_analisa, val.harga).toString()).detail}</Td>
                </tr>
              ))
              : <tr>
                  <Td colSpan={9}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </tr>
            }
          </tbody>
          <tfoot>
            <tr className="bg-light">
              <Td textRight colSpan={8}>
                <b style={{fontSize: 14}}>Total</b>
              </Td>
              <Td textRight>
                <b style={{fontSize: 14}}>
                  {RupiahConvert(getGrandTotal().toString()).detail}
                </b>
              </Td>
            </tr>
          </tfoot>
        </Table>
      </div>
      <PopUpAlert 
        show={alertConfig.show}
        onConfirm={alertConfig.hide}
        onHide={alertConfig.hide}
        title={alertConfig.title}
        text={alertConfig.text}
        type={alertConfig.type}
      />
    </>
  )
}

export default TabBahanSection
