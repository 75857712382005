import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, ButtonGroup } from "react-bootstrap";
import Axios from 'axios';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  ReadButton,
  UpdateButton,
  DeleteButton,
  Switch,
  InputSearch,
  SelectSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  DeleteModal
} from "components";
import { PageNumber as TableNumber } from "utilities";
import { RegistrasiBarangJadiApi } from "api";

const RegistrasiBarangJadi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [dataFilter, setDataFilter] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [deleteData, setDeleteData] = useState({
    show: false,
    data: {
      id_item_buaso: "",
    },
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    const query = {
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    }

    Boolean(selectedFilter) && (query.id_jenis = selectedFilter)

    Axios.all([
      RegistrasiBarangJadiApi.page(query),
      RegistrasiBarangJadiApi.dropdown({ tipe: 'jenis', id_kelompok: '7' }),
    ])
      .then(Axios.spread((res, dropdown) => {
        const dropdownFilter = dropdown?.data?.data?.map(item => ({ value: item.id_jenis, label: item.nama_jenis }));

        setDataFilter([{ value: '', label: 'Semua Jenis Barang Jadi' }, ...dropdownFilter]);
        setData(res?.data?.data ?? [])
        setPaginationConfig({
          ...paginationConfig,
          dataCount: res?.data?.data_count,
          totalPage: res?.data?.total_page,
        });
      }))
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const dismissAlert = () => {
    let state = { ...history.location.state }
    delete state.alert
    history.replace({ ...history.location, state })

    setAlertConfig({
      ...alertConfig,
      show: false,
    })
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  const changeDataStatus = (status, id_item_atribut) => {
    setIsPageLoading(true);
    const value = { id_item_atribut };

    const onLoadedSuccess = () => {
      setAlertConfig({
        show: true,
        variant: "primary",
        text: "Ubah status data berhasil",
      });
    };

    const onLoadedFailed = () => {
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "Ubah status data gagal",
      });
    };

    status === true
      ? RegistrasiBarangJadiApi.show(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getInitialData())
      : RegistrasiBarangJadiApi.hide(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getInitialData());
  };

  useEffect(() => {
    setNavbarTitle("Barang Jadi");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [setNavbarTitle, searchConfig.key, paginationConfig.page, paginationConfig.dataLength, selectedFilter]);

  const HapusModal = () => {
    const [btnLoading, setBtnLoading] = useState(false);

    const deleteDataHandler = () => {
      setBtnLoading(true);

      RegistrasiBarangJadiApi.delete({ id_item_buaso: deleteData.data.id_item_buaso })
        .then(() => setAlertConfig({ show: true, variant: "primary", text: "Hapus data berhasil!" }))
        .catch(() => setAlertConfig({ show: true, variant: "danger", text: "Hapus data gagal!" }))
        .finally(() => {
          setDeleteData({ ...deleteData, show: false });
          getInitialData();
        });
    };

    return (
      <DeleteModal
        show={deleteData.show}
        onHide={() => setDeleteData({ ...deleteData, show: false })}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title='Hapus Barang Jadi'>
        <div>Kode Item : {deleteData.data.kode_item}</div>
        <div>Nama Barang Jadi : {deleteData.data.nama_item}</div>
      </DeleteModal>
    );
  };

  const PageContent = () => {
    const checkStatus = (stat) => {
      if (stat) return <span style={{ color: '#00CC66' }}>Sudah Dianalisa</span>
      return <span style={{ color: '#FF0000' }}>Belum Dianalisa</span>;
    };

    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed className='text-nowrap'>Kode <br />Barang Jadi</ThFixed>
            <Th>Nama Barang Jadi</Th>
            <Th>Kelompok Barang Jadi</Th>
            <Th>Jenis Barang Jadi</Th>
            <Th>Satuan Jual</Th>
            <ThFixed>Status Analisa</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}
              </TdFixed>
              <TdFixed className="align-middle">
                <div className="d-flex justify-content-center">
                  <ButtonGroup>
                    <ReadButton
                      size="sm"
                      onClick={() => history.push(`/master/registrasi-barang-jadi/detail/${val.id_item_atribut}`)} />
                    <UpdateButton
                      size="sm"
                      onClick={() => history.push(`/master/registrasi-barang-jadi/ubah/${val.id_item_atribut}`)} />
                    <DeleteButton
                      size="sm"
                      onClick={() => setDeleteData({ show: true, data: val })} />
                  </ButtonGroup>

                  <Switch
                    id={toString(index + 1)}
                    checked={val.is_hidden === false ? true : false}
                    onChange={() => changeDataStatus(val.is_hidden, val.id_item_atribut)}
                  />
                </div>
              </TdFixed>
              <Td className="text-center">{val.kode_item}</Td>
              <Td >{`${val.nama_item ?? ''} ${val.nama_varian ? `[${val.nama_varian}]` : ''}`}</Td>
              <Td >{val.nama_kelompok}</Td>
              <Td>{val.nama_jenis}</Td>
              <Td>{val.nama_satuan_jual}</Td>
              <TdFixed className='text-center'>{checkStatus(val.is_abj)}</TdFixed>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>

      <Row className='d-flex justify-content-between align-items-center'>
        <Col md={4}>
          <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
        </Col>
        <Col md={6} className='d-flex flex-row justify-content-end align-items-end pb-2'>
          {!isPageLoading && <SelectSearch
            wrapperStyle={{ width: 300 }}
            wrapperClassName='mr-3'
            placeholder="Filter Jenis Barang Jadi"
            onChange={(val) => setSelectedFilter(val.value)}
            option={dataFilter ?? [{ value: '', label: 'Semua Jenis Barang Jadi' }]}
            defaultValue={dataFilter?.find(item => item.value === selectedFilter) ?? ''}
          />}
          <ActionButton
            size='md'
            className='mb-1'
            text="Tambah Data"
            onClick={() => history.push("/master/registrasi-barang-jadi/tambah")} />
        </Col>
      </Row>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => dismissAlert()} />

      {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}

      {deleteData.show && <HapusModal />}
    </CRUDLayout>
  );
};

export default RegistrasiBarangJadi;
