import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card, Nav } from "react-bootstrap";
import Axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import { 
  DataStatus, BackButton, ActionButton, PopUpAlert, Td,
  Table, TdFixed, ThFixed, Th
} from "components";
import { RupiahConvert } from "utilities";
import { JobMixApi } from "api";
import {
  InfoSection,
  PabrikSection,
  TabBahanSection,
  TabUpahSection,
  TabAlatMesinSection,
  TabSubkonSection,
  TabOverheadSection,
} from "./Section";

const CreateJobMix = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  const [isGudangLoading, setIsGudangLoading] = useState(false);
  const [dataSalesOrder, setDataSalesOrder] = useState({});
  const [dataPabrik, setDataPabrik] = useState([]);
  const [dataGudangBarang, setDataGudangBarang] = useState([]);
  const [dataKetersediaanAlatMesin, setDataKetersediaanAlatMesin] = useState([]);
  const [dataBahan, setDataBahan] = useState([]);
  const [dataUpah, setDataUpah] = useState([]);
  const [dataAlatMesin, setDataAlatMesin] = useState([]);
  const [dataSubcont, setDataSubcont] = useState([]);
  const [dataOverhead, setDataOverhead] = useState([]);
  const [dataPermintaanBahan, setDataPermintaanBahan] = useState([]);
  const [dataPermintaanUpah, setDataPermintaanUpah] = useState([]);
  const [dataPermintaanAlatMesin, setDataPermintaanAlatMesin] = useState([]);
  const [dataPermintaanSubcont, setDataPermintaanSubcont] = useState([]);
  const [dataPermintaanOverhead, setDataPermintaanOverhead] = useState([]);
  const [dataQty, setDataQty] = useState("");
  const [selectedPabrik, setSelectedPabrik] = useState(null);
  const [selectedTabs, setSelectedTabs] = useState("bahan");
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    type: "",
    title: "",
    text: "",
    hide: () => setAlertConfig({ ...alertConfig, show: false }),
  });

  // Mapping data
  const mappingDataSalesOrder = (data) => {
    return {
      id_sales_order: data.id_sales_order ?? null,
      id_sales_order_detail: data.id_sales_order_detail ?? null,
      tgl_sales_order: data.tgl_sales_order ?? null,
      no_sales_order: data.no_sales_order ?? "-",
      id_barang: data.id_item_atribut ?? null,
      barang_jadi: data.nama_barang ?? "-",
      customer: data.nama_customer ?? "-",
      qty_sales_order: data.qty_item ? parseFloat(data.qty_item) : 0,
      satuan: data.nama_satuan ?? "",
    };
  };
  const mappingDataItemGudang = (data) =>
    data.map((val) => {
      return {
        id_pabrik: val.id_pabrik ?? null,
        id_gudang: val.id_gudang ?? null,
        nama_item: val.nama_item ?? "-",
        satuan: val.kode_satuan ?? "-",
        stok: val.jumlah ? parseFloat(val.jumlah) : 0,
      };
    });
  const mappingDataGudang = (data) =>
    data.map((val) => {
      return {
        id_gudang: val.id_gudang ?? null,
        id_pabrik: val.id_pabrik ?? null,
        nama_gudang: val.nama_gudang ?? "",
        type: val.flag_gudang ?? "",
        item: mappingDataItemGudang(val.barang ?? []),
      };
    });
  const mappingDataPabrik = (data) =>
    data.map((val) => {
      return {
        id_pabrik: val.id_pabrik ?? null,
        kode_pabrik: val.kode_pabrik ?? "-",
        nama_pabrik: val.nama_pabrik ?? "-",
        jenis_pabrik: val.jenis_pabrik ?? "-",
        status_jobmix: "Available",
        gudang: mappingDataGudang(val.gudang ?? []),
      };
    });
  const mappingDataBahan = (data, qty) =>
    data.map((val) => {
      return {
        id_item: val.id_item_buaso ?? null,
        id_buaso: val.id_buaso ?? null,
        kode_item: val.kode_item ?? "-",
        nama_item: val.nama_item ?? "-",
        satuan: val.nama_satuan ?? "-",
        kode_satuan: val.kode_satuan ?? "",
        qty_produksi: val.qty_produksi,
        qty_analisa: val.qty_produksi
          ? parseFloat(parseFloat(val.qty_produksi) * qty)
          : 0,
        id_gudang: null,
        gudang: null,
        stok: null,
        harga: val.harga_satuan_bahan ? parseInt(val.harga_satuan_bahan) : 0,
        status: null,
        qty_produksi: val.qty_produksi
      };
    });
  const mappingDataUpah = (data, qty) =>
    data.map((val) => {
      return {
        id_item: val.id_item_buaso ?? null,
        id_buaso: val.id_buaso ?? null,
        kode_item: val.kode_item ?? "-",
        nama_item: val.nama_item ?? "-",
        satuan: val.nama_satuan ?? "-",
        kode_satuan: val.kode_satuan ?? "",
        qty_produksi: val.qty_produksi,
        qty_analisa: val.qty_produksi
          ? parseFloat(parseFloat(val.qty_produksi) * qty)
          : 0,
        harga: val.harga_satuan_upah ? parseInt(val.harga_satuan_upah) : 0,
      };
    });
  const mappingDataAlatMesin = (data, qty) =>
    data.map((val) => {
      return {
        id_item: val.id_item_buaso ?? null,
        id_buaso: val.id_buaso ?? null,
        kode_item: val.kode_item ?? "-",
        nama_item: val.nama_item ?? "-",
        satuan: val.nama_satuan ?? "-",
        kode_satuan: val.kode_satuan ?? "",
        tersedia: val.ketersediaan ? true : false,
        qty_produksi: val.qty_produksi,
        qty_analisa: val.qty_produksi
          ? parseFloat(parseFloat(val.qty_produksi) * qty)
          : 0,
        harga: val.biaya_penyusutan_per_jam ? parseInt(val.biaya_penyusutan_per_jam) : 0,
      };
    });
  const mappingDataSubcont = (data, qty) =>
    data.map((val) => {
      return {
        id_item: val.id_item_buaso ?? null,
        id_buaso: val.id_buaso ?? null,
        kode_item: val.kode_item ?? "-",
        nama_item: val.nama_item ?? "-",
        satuan: val.nama_satuan ?? "-",
        kode_satuan: val.kode_satuan ?? "",
        qty_produksi: val.qty_produksi,
        qty_analisa: val.qty_produksi
          ? parseFloat(parseFloat(val.qty_produksi) * qty)
          : 0,
        harga: val.harga_satuan_subcont ? parseInt(val.harga_satuan_subcont) : 0,
      };
    });
  const mappingDataOverhead = (data, qty) =>
    data.map((val) => {
      return {
        id_item: val.id_item_buaso ?? null,
        id_buaso: val.id_buaso ?? null,
        kode_item: val.kode_item ?? "-",
        nama_item: val.nama_item ?? "-",
        satuan: val.nama_satuan ?? "-",
        kode_satuan: val.kode_satuan ?? "",
        qty_produksi: val.qty_produksi,
        qty_analisa: val.qty_produksi
          ? parseFloat(parseFloat(val.qty_produksi) * qty)
          : 0,
        harga: val.harga_satuan_overhead ? parseInt(val.harga_satuan_overhead) : 0,
      };
    });
  const mappingDataDropdownBarang = (data) =>
    data.map((val) => {
      return {
        id_gudang: val.id_gudang,
        nama_gudang: val.nama_gudang,
        id_item: val.id_item_buaso,
        stok: val.jumlah ? parseFloat(val.jumlah) : 0,
      };
    });
  const mappingDataKetersediaanAlatMesin = (data) =>
    data.map((val) => {
      return {
        id_gudang: val.id_gudang,
        nama_gudang: val.nama_gudang,
        id_item: val.id_item_buaso,
        stok: val.qty_gudang_produksi ? parseFloat(val.qty_gudang_produksi) : 0,
        ketersediaan: val.ketersediaan ?? false,
      };
    });

  // Fetching data pada saat halaman pertama kali dimuat
  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    Axios.all([JobMixApi.getSingle({ id_sales_order_detail: id }), JobMixApi.getPabrik()])
      .then(
        Axios.spread((so, pabrik) => {
          const rawDataSO = so.data.data;
          const rawDataPabrik = pabrik.data.data;
          const rawDataBahan = rawDataSO.analisa_bahan;
          const rawDataUpah = rawDataSO.analisa_upah;
          const rawDataAlatMesin = rawDataSO.analisa_alat_mesin;
          const rawDataSubcont = rawDataSO.analisa_subcont;
          const rawDataOverhead = rawDataSO.analisa_overhead;
          const mapDataSO = mappingDataSalesOrder(rawDataSO ?? {});
          const mapDataPabrik = mappingDataPabrik(rawDataPabrik ?? []);
          const mapDataBahan = mappingDataBahan(rawDataBahan ?? [], mapDataSO?.qty_sales_order ?? 0);
          const mapDataUpah = mappingDataUpah(rawDataUpah ?? [], mapDataSO?.qty_sales_order ?? 0);
          const mapDataAlatMesin = mappingDataAlatMesin(rawDataAlatMesin ?? [], mapDataSO?.qty_sales_order ?? 0);
          const mapDataSubcont = mappingDataSubcont(rawDataSubcont ?? [], mapDataSO?.qty_sales_order ?? 0);
          const mapDataOverhead = mappingDataOverhead(rawDataOverhead ?? [], mapDataSO?.qty_sales_order ?? 0);

          setDataSalesOrder(mapDataSO);
          setDataPabrik(mapDataPabrik);
          setDataBahan(mapDataBahan);
          setDataUpah(mapDataUpah);
          setDataAlatMesin(mapDataAlatMesin);
          setDataSubcont(mapDataSubcont);
          setDataOverhead(mapDataOverhead);

          setFetchingStatus({
            loading: false,
            success: true,
          });
        })
      )
      .catch(() => {
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  useEffect(() => {
    dataQty && dataQty !== 0 && dataQty !== "" && Axios.all([JobMixApi.getSingle({ id_sales_order_detail: id })])
      .then(
        Axios.spread((so) => {
          const rawDataSO = so.data.data;
          const rawDataBahan = rawDataSO.analisa_bahan;
          const rawDataUpah = rawDataSO.analisa_upah;
          const rawDataAlatMesin = rawDataSO.analisa_alat_mesin;
          const rawDataSubcont = rawDataSO.analisa_subcont;
          const rawDataOverhead = rawDataSO.analisa_overhead;
          const mapDataBahan = mappingDataBahan(rawDataBahan ?? [], dataQty ? parseInt(dataQty) : 0);
          const mapDataUpah = mappingDataUpah(rawDataUpah ?? [], dataQty ? parseInt(dataQty) : 0);
          const mapDataAlatMesin = mappingDataAlatMesin(rawDataAlatMesin ?? [], dataQty ? parseInt(dataQty) : 0);
          const mapDataSubcont = mappingDataSubcont(rawDataSubcont ?? [], dataQty ? parseInt(dataQty) : 0);
          const mapDataOverhead = mappingDataOverhead(rawDataOverhead ?? [], dataQty ? parseInt(dataQty) : 0);

          setDataBahan(mapDataBahan);
          setDataUpah(mapDataUpah);
          setDataAlatMesin(mapDataAlatMesin);
          setDataSubcont(mapDataSubcont);
          setDataOverhead(mapDataOverhead);
        })
      )
  }, [dataQty])

  // Check apakah dapat melakukan tunda produksi
  const checkTundaProduksi = () => {
    const checkBahan = () => {
      const checkStok = Boolean(dataBahan.find((val) => !val.id_gudang));

      if (!checkStok) {
        return true;
      }

      return false;
    };

    if (checkBahan()) {
      return true;
    }
  };

  // Check apakah dapat melakukan submit produksi
  const checkProduksi = () => {
    const checkBahan = () => {
      const checkLengthBahanTersedia = dataBahan.filter(
        (val) => val.stok >= val.qty_analisa
      ).length;
      const checkLengthSemuaBahan = dataBahan.length;

      if (checkLengthSemuaBahan === checkLengthBahanTersedia) {
        return true;
      }

      return false;
    };

    const checkUpah = () => {
      if (dataUpah.length < 1) {
        return true;
      } else if (dataPermintaanUpah.length < 1 || dataPermintaanUpah.length < dataUpah.length) {
        return false;
      }

      return true;
    };

    const checkAlatMesin = () => {
      if (dataAlatMesin.length < 1) {
        return true;
      } else if (
        dataPermintaanAlatMesin.length < 1 ||
        dataPermintaanAlatMesin.length < dataAlatMesin.length
      ) {
        return false;
      }

      return true;
    };

    const checkSubcont = () => {
      if (dataSubcont.length < 1) {
        return true;
      } else if (
        dataPermintaanSubcont.length < 1 ||
        dataPermintaanSubcont.length < dataSubcont.length
      ) {
        return false;
      }

      return true;
    };

    const checkOverhead = () => {
      if (dataOverhead.length < 1) {
        return true;
      } else if (
        dataPermintaanOverhead.length < 1 ||
        dataPermintaanOverhead.length < dataOverhead.length
      ) {
        return false;
      }

      return true;
    };

    if (checkBahan()) {
    // if (checkBahan() && checkUpah() && checkAlatMesin() && checkSubcont() && checkOverhead()) {
      return true;
    }
  };

  const InfoItem = ({ no, buaso, total }) => (
    <tr>
      <TdFixed>{no}</TdFixed>
      <Td>{buaso}</Td>
      <TdFixed>{total}</TdFixed>
    </tr>
  )

  // Konfigurasi komponen tabs
  const tabsConfig = [
    {
      tab: "bahan",
      label: "Bahan",
      component: () => (
        <TabBahanSection
          selectedPabrik={selectedPabrik}
          dataBahan={dataBahan}
          dataPermintaanBahan={dataPermintaanBahan}
          setDataBahan={setDataBahan}
          setDataPermintaanBahan={setDataPermintaanBahan}
          dataGudangBarang={dataGudangBarang}
          isGudangLoading={isGudangLoading}
          dataQty={dataQty}
        />
      ),
    },
    {
      tab: "upah",
      label: "Upah",
      component: () => (
        <TabUpahSection
          selectedPabrik={selectedPabrik}
          dataUpah={dataUpah}
          dataPermintaanUpah={dataPermintaanUpah}
          setDataPermintaanUpah={setDataPermintaanUpah}
        />
      ),
    },
    {
      tab: "alat",
      label: "Alat & Mesin",
      component: () => (
        <TabAlatMesinSection
          selectedPabrik={selectedPabrik}
          dataAlatMesin={dataAlatMesin}
          dataPermintaanAlatMesin={dataPermintaanAlatMesin}
          dataKetersediaanAlatMesin={dataKetersediaanAlatMesin}
          setDataPermintaanAlatMesin={setDataPermintaanAlatMesin}
        />
      ),
    },
    {
      tab: "subkon",
      label: "Subcont",
      component: () => (
        <TabSubkonSection
          selectedPabrik={selectedPabrik}
          dataSubcont={dataSubcont}
          dataPermintaanSubcont={dataPermintaanSubcont}
          setDataPermintaanSubcont={setDataPermintaanSubcont}
        />
      ),
    },
    {
      tab: "overhead",
      label: "Overhead",
      component: () => (
        <TabOverheadSection
          selectedPabrik={selectedPabrik}
          dataOverhead={dataOverhead}
          dataPermintaanOverhead={dataPermintaanOverhead}
          setDataPermintaanOverhead={setDataPermintaanOverhead}
        />
      ),
    },
  ];

  // Mendapatkan data dropdown pada analisa bahan
  const getGudangProduksi = (pabrik) => {
    setIsGudangLoading(true);

    const getIdGudang = pabrik?.gudang?.map((val) => val.id_gudang) ?? [];
    const getIdBahan = dataBahan?.map((val) => val.id_item) ?? [];
    const getIdBarangJadi = dataSalesOrder.id_barang;
    const getIdPabrik = pabrik.id_pabrik;

    setTimeout(() => {
      JobMixApi.getStokBarangGudang({
        id_gudang: getIdGudang,
        id_item_buaso: getIdBahan,
        id_barang_jadi: getIdBarangJadi,
        id_pabrik: getIdPabrik,
      })
        .then((res) => {
          const rawDataBahan = res.data.data.bahan;
          const rawDataAlat = res.data.data.analisa_alat_mesin;
          const mapDataBahan = mappingDataDropdownBarang(rawDataBahan ?? []);
          const mapDataAlat = mappingDataKetersediaanAlatMesin(rawDataAlat ?? []);
          setDataGudangBarang(mapDataBahan);
          setDataKetersediaanAlatMesin(mapDataAlat);
        })
        .catch(() => {
          window.alert("Data gudang analisa barang jadi gagal diperoleh!");
        })
        .finally(() => setIsGudangLoading(false));
    }, 100);
  };

  // Menangani perubahan pada tabs
  const onTabsChangeHandler = (e, newPage) => {
    e.preventDefault();
    setSelectedTabs(newPage);
  };

  // Menangani perubahan pabrik
  const onPabrikChange = (data) => {
    const resetDataBahan = () => {
      const newDataBahan = dataBahan.map((val) => {
        return {
          ...val,
          id_gudang: null,
          stok: 0,
          status: null,
        };
      });

      setDataBahan(newDataBahan);
      setDataPermintaanBahan([]);
      setDataPermintaanUpah([]);
      setDataPermintaanAlatMesin([]);
      setDataPermintaanSubcont([]);
      setDataPermintaanOverhead([]);
    };

    getGudangProduksi(data);
    setSelectedPabrik(data);
    resetDataBahan();
  };

  // Hitung grand total semua item
  const getGrandTotal = () => {
    const getTotal = (data) =>
      data.reduce((prev, current) => {
        const total = current.qty_analisa * current.harga;

        return prev + total;
      }, 0);

    const totalBahan = getTotal(dataBahan);
    const totalUpah = getTotal(dataUpah);
    const totalAlat = getTotal(dataAlatMesin);
    const totalSubcont = getTotal(dataSubcont);
    const totalOverhead = getTotal(dataOverhead);

    return {
      total: totalBahan + totalUpah + totalAlat + totalSubcont + totalOverhead,
      bahan: totalBahan,
      upah: totalUpah,
      alat: totalAlat,
      subcont: totalSubcont,
      overhead: totalOverhead
    }
  };

  // Formik data handler
  const formInitialValues = {
    tanggal: "",
    nomor: "",
    qty_jobmix: dataSalesOrder.qty_sales_order,
  };
  const formValidationSchema = Yup.object().shape({
    tanggal: Yup.string().required("Pilih tanggal"),
    nomor: Yup.string().required("Nomor dibutuhkan, pilih tanggal untuk menentukan nomor"),
    qty_jobmix: Yup.string().required("Masukan Qty. Jobmix"),
  });
  const formSubmitHandler = (values, { setSubmitting }) => {
    if (!selectedPabrik) {
      setSubmitting(false);
      return setAlertConfig({
        ...alertConfig,
        show: true,
        type: "info",
        title: "Pilih pabrik terlebih dahulu",
        text: "",
        hide: () =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          }),
      });
    }

    const mappingPermintaan = (data) =>
      data.map((val) => {
        return {
          tgl_jobmix_permintaan: val.tgl_permintaan,
          id_jobmix_permintaan: null,
          id_buaso: val.id_buaso,
          qty_permintaan: val.qty_permintaan,
          id_item_permintaan: val.id_item,
        };
      });

    const analisaBahan = dataBahan.map((val) => {
      return {
        id_item_buaso: val.id_item,
        qty_produksi: dataQty && val.qty_analisa / parseInt(dataQty),
        id_gudang: val.id_gudang,
        harga_satuan_bahan: val.harga,
      };
    });
    const analisaUpah = dataUpah.map((val) => {
      return {
        id_item_buaso: val.id_item,
        qty_produksi: val.qty_produksi,
        harga_satuan_upah: val.harga,
      };
    });
    const analisaAlatMesin = dataAlatMesin.map((val) => {
      return {
        id_item_aset: val.id_item,
        qty_produksi: val.qty_produksi,
        biaya_penyusutan_per_jam: val.harga,
      };
    });
    const analisaSubcont = dataSubcont.map((val) => {
      return {
        id_item_buaso: val.id_item,
        qty_produksi: val.qty_produksi,
        harga_satuan_subcont: val.harga,
      };
    });
    const analisaOverhead = dataOverhead.map((val) => {
      return {
        id_item_buaso: val.id_item,
        qty_produksi: val.qty_produksi,
        harga_satuan_overhead: val.harga,
      };
    });

    const permintaanBahan = mappingPermintaan(dataPermintaanBahan);
    const permintaanUpah = mappingPermintaan(dataPermintaanUpah);
    const permintaanAlatMesin = mappingPermintaan(dataPermintaanAlatMesin);
    const permintaanSubcont = mappingPermintaan(dataPermintaanSubcont);
    const permintaanOverhead = mappingPermintaan(dataPermintaanOverhead);
    const dataPermintaan = [
      ...permintaanBahan,
      ...permintaanUpah,
      ...permintaanAlatMesin,
      ...permintaanSubcont,
      ...permintaanOverhead,
    ];
    const rawValues = {
      tgl_jobmix: values.tanggal,
      qty_jobmix: values.qty_jobmix,
      id_item_buaso: dataSalesOrder.id_barang,
      id_sales_order: dataSalesOrder.id_sales_order,
      id_sales_order_detail: dataSalesOrder.id_sales_order_detail,
      id_pabrik: selectedPabrik.id_pabrik ?? null,
      jobmix_permintaan: dataPermintaan,
      analisa_bahan: analisaBahan,
      analisa_upah: analisaUpah,
      analisa_alat_mesin: analisaAlatMesin,
      analisa_subcont: analisaSubcont,
      analisa_overhead: analisaOverhead,
      id_unit_produksi: sessionStorage.getItem('id_unit_produksi') ?? null
    };
    let finalValues = {};

    if (checkProduksi()) {
      finalValues = {
        ...rawValues,
        is_mulai: true,
      };
    } else {
      finalValues = {
        ...rawValues,
        is_mulai: false,
      };
    }

    JobMixApi.save(finalValues)
      .then(() => {
        setAlertConfig({
          ...alertConfig,
          show: true,
          type: "success",
          title: "Tambah Job Mix Berhasil",
          text: checkProduksi()
            ? "Job mix berhasil ditambah & produksi dimulai!"
            : "Job mix berhasil ditambah & produksi masih ditunda!",
          hide: () => history.push("/transaksi/job-mix"),
        });
      })
      .catch(() => {
        setSubmitting(false);
        setAlertConfig({
          ...alertConfig,
          show: true,
          type: "danger",
          title: "Tambah Job Mix Gagal",
          text: "Data gagal ditambah!",
          hide: () =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            }),
        });
      });
  };

  useEffect(() => {
    setNavbarTitle("Tambah Job Mix");
    getInitialData();
    setSelectedTabs(tabsConfig[0].tab);

    return () => {
      setFetchingStatus({
        loading: true,
        success: false,
      });
    };
  }, []);

  const TabsNav = ({ tab, label }) => (
    <Nav.Item>
      <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
        {label}
      </Nav.Link>
    </Nav.Item>
  );

  // Check jika fetching status loading atau gagal dimuat
  if (!fetchingStatus.success || fetchingStatus.loading) {
    return (
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <b>Tambah Job Mix</b>
          <BackButton size="sm" onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          {/* Menampilkan tampilan loading */}
          {fetchingStatus.loading && <DataStatus loading text="Memuat data . . ." />}

          {/* Menampilkan tampilan pada saat data gagal dimuat */}
          {!fetchingStatus.loading && !fetchingStatus.success && (
            <DataStatus text="Data gagal dimuat!" />
          )}
        </Card.Body>
      </Card>
    );
  }

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidationSchema}
      onSubmit={formSubmitHandler}
    >
      {(formik) => (
        <>
          {/* Menampilkan bagian info & form */}
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <b>Informasi Umum</b>
              <BackButton size="sm" onClick={() => history.goBack()} />
            </Card.Header>
            <Card.Body>
              <InfoSection dataSalesOrder={dataSalesOrder} formik={formik} setDataQty={setDataQty} />
            </Card.Body>
          </Card>

          {/* Menampilkan slider pabrik */}
          <PabrikSection
            dataPabrik={dataPabrik}
            selectedPabrik={selectedPabrik}
            onPabrikChange={onPabrikChange}
          />

          {/* Menampilkan analisa job mix */}
          <div className="mt-3">
            <h6 className="px-2 font-weight-bold">Analisa Barang Jadi</h6>
            <Card>
              <Card.Header>
                <Nav variant="tabs" defaultActiveKey={`#${selectedTabs}`}>
                  {tabsConfig.map((val, index) => (
                    <TabsNav key={index} tab={val.tab} label={val.label} />
                  ))}
                </Nav>
              </Card.Header>
              <Card.Body>
                {selectedPabrik ? (
                  tabsConfig.map(
                    ({ tab, component: Component }, index) =>
                      tab === selectedTabs && <Component key={index} />
                  )
                ) : (
                  <DataStatus text="Pilih pabrik untuk menganalisa" />
                )}
              </Card.Body>
            </Card>
          </div>

          {/* Menampilkan bagian button */}
          <Card className="my-4">
            <Card.Header>
              <b>Proses Produksi</b>
            </Card.Header>
            <Card.Body>
              {/* Bagian grand total */}
              <Table>
                <thead className="bg-light">
                  <tr>
                    <ThFixed>No.</ThFixed>
                    <Th>BUASO</Th>
                    <ThFixed>Total</ThFixed>
                  </tr>
                </thead>
                <tbody>
                  <InfoItem no={1} buaso="Bahan" total={RupiahConvert(getGrandTotal().bahan.toString()).detail} />
                  <InfoItem no={2} buaso="Upah" total={RupiahConvert(getGrandTotal().upah.toString()).detail} />
                  <InfoItem no={3} buaso="Alat & Mesin" total={RupiahConvert(getGrandTotal().alat.toString()).detail} />
                  <InfoItem no={4} buaso="Subkon" total={RupiahConvert(getGrandTotal().subcont.toString()).detail} />
                  <InfoItem no={5} buaso="Overhead" total={RupiahConvert(getGrandTotal().overhead.toString()).detail} />
                </tbody>
                <tfoot>
                  <tr className="bg-light">
                    <Td textRight colSpan={2}>
                      <b style={{ fontSize: 14 }}>Grand Total</b>
                    </Td>
                    <Td textRight>
                      <b style={{ fontSize: 14 }}>{RupiahConvert(getGrandTotal().total.toString()).detail}</b>
                    </Td>
                  </tr>
                </tfoot>
              </Table>

              {/* Bagian tombol */}
              <div className="d-flex justify-content-end mt-4">
                {!checkProduksi() && (
                  <ActionButton
                    variant="warning"
                    text="Tunda Produksi"
                    className="mr-2 text-white"
                    onClick={formik.handleSubmit}
                    loading={formik.isSubmitting}
                    disable={!checkTundaProduksi()}
                  />
                )}
                <ActionButton
                  text="Mulai Produksi"
                  onClick={formik.handleSubmit}
                  disable={!checkProduksi()}
                  loading={checkProduksi() && formik.isSubmitting}
                />
              </div>
            </Card.Body>
          </Card>

          {/* Alert */}
          <PopUpAlert
            show={alertConfig.show}
            onConfirm={alertConfig.hide}
            onHide={alertConfig.hide}
            title={alertConfig.title}
            text={alertConfig.text}
            type={alertConfig.type}
          />
        </>
      )}
    </Formik>
  );
};

export default CreateJobMix;
