import Services from '../../services'

class AuthApi {
  login(value) {
    return Services.post('/auth/login', value)
  }
  
  profile() {
    return Services.get("/auth/profile");
  }
}

export default new AuthApi()