import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { InfoItemVertical, DatePicker, Input } from "components";
import { DateConvert } from "utilities";
import { JobMixApi } from "api";

const InfoSection = ({ dataSalesOrder, formik, setDataQty }) => {
  const today = DateConvert(new Date()).default;
  const { values, errors, touched, setValues } = formik;
  const [isNomorLoading, setIsNomorLoading] = useState(false);

  // Menangani fetching nomor
  const getNomorHandler = (date) => {
    setIsNomorLoading(true);

    JobMixApi.getNomor({ tanggal: date })
      .then((res) => {
        const nomorBaru = res.data.data;

        setValues({
          ...values,
          tanggal: date,
          nomor: nomorBaru,
        });
      })
      .catch(() => {
        window.alert("Nomor gagal dimuat!");
        setValues({
          ...values,
          tanggal: "",
          nomor: "",
        });
      })
      .finally(() => {
        setIsNomorLoading(false);
      });
  };

  useEffect(() => {
    getNomorHandler(today);

    return () => {
      setIsNomorLoading(false);
    };
  }, []);

  return (
    <>
      {/* Info section */}
      <Row>
        <Col sm>
          <InfoItemVertical
            label="Tgl. Sales Order"
            text={
              dataSalesOrder.tgl_sales_order
                ? DateConvert(new Date(dataSalesOrder.tgl_sales_order)).detail
                : "-"
            }
          />
          <InfoItemVertical label="No. Sales Order" text={dataSalesOrder.no_sales_order} />
        </Col>
        <Col sm>
          <InfoItemVertical label="Barang Jadi" text={dataSalesOrder.barang_jadi} />
          <InfoItemVertical label="Customer" text={dataSalesOrder.customer} />
        </Col>
        <Col sm>
          <InfoItemVertical
            label="Qty. Sales Order"
            text={`${dataSalesOrder.qty_sales_order} ${dataSalesOrder.satuan}`}
          />
        </Col>
      </Row>

      <hr />

      {/* Form section */}
      <Row>
        <Col sm>
          <DatePicker
            label="Tanggal Job Mix"
            placeholderText={isNomorLoading ? "Memuat tanggal . . . " : "Pilih tanggal"}
            selected={isNomorLoading ? "" : values.tanggal ? new Date(values.tanggal) : ""}
            onChange={(date) => getNomorHandler(DateConvert(date).default)}
            error={Boolean(errors.tanggal && touched.tanggal)}
            errorText={Boolean(errors.tanggal && touched.tanggal) && errors.tanggal}
          />
        </Col>
        <Col sm>
          <Input
            readOnly
            label="Nomor Job Mix"
            placeholder="Pilih tanggal untuk menentukan nomor"
            value={isNomorLoading ? "Memuat nomor . . ." : values.nomor}
            error={Boolean(errors.nomor && touched.nomor)}
            errorText={Boolean(errors.nomor && touched.nomor) && errors.nomor}
          />
        </Col>
        <Col sm>
          <Input
            label="Qty. Job Mix"
            type="number"
            placeholder="Masukan Qty. Job Mix"
            value={values.qty_jobmix}
            min="0"
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              const val = e.target.value;
              if (val > dataSalesOrder.qty_sales_order) {
                setValues({ ...values, qty_jobmix: "" })
                setDataQty("")
              } else {
                setValues({ ...values, qty_jobmix: val })
                setDataQty(val)
              }
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default InfoSection;
