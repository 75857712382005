import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { InfoItemVertical, DatePicker, Input } from "components";
import { DateConvert } from "utilities";
import { JobMixApi } from "api";

const InfoSection = ({ dataJobMix, formik, setDataQty }) => {
  const { values, errors, touched, setValues } = formik;

  // Menangani fetching nomor
  const getNomorHandler = (date) => {
    setValues({
      ...values,
      tanggal: date,
    });
  };

  useEffect(() => {}, []);

  return (
    <>
      {/* Info section */}
      <Row>
        <Col sm>
          <InfoItemVertical
            label="Tgl. Sales Order"
            text={
              dataJobMix.tgl_sales_order
                ? DateConvert(new Date(dataJobMix.tgl_sales_order)).detail
                : "-"
            }
          />
          <InfoItemVertical label="No. Sales Order" text={dataJobMix.no_sales_order} />
        </Col>
        <Col sm>
          <InfoItemVertical label="Barang Jadi" text={dataJobMix.barang_jadi} />
          <InfoItemVertical label="Customer" text={dataJobMix.customer} />
        </Col>
        <Col sm>
          <InfoItemVertical
            label="Qty. Sales Order"
            text={`${dataJobMix.qty_sales_order} ${dataJobMix.satuan}`}
          />
          <InfoItemVertical
            label="Qty. Sisa Sales Order"
            text={`${parseInt(dataJobMix.qty_sisa)} ${dataJobMix.satuan}`}
          />
        </Col>
      </Row>

      <hr />

      {/* Form section */}
      <Row>
        <Col sm>
          <DatePicker
            label="Tanggal Job Mix"
            placeholderText="Pilih tanggal"
            selected={values.tanggal ? new Date(values.tanggal) : ""}
            onChange={(date) => getNomorHandler(DateConvert(date).default)}
            error={Boolean(errors.tanggal && touched.tanggal)}
            errorText={Boolean(errors.tanggal && touched.tanggal) && errors.tanggal}
          />
        </Col>
        <Col sm>
          <Input
            readOnly
            label="Nomor Job Mix"
            placeholder="Pilih tanggal untuk menentukan nomor"
            value={values.nomor}
            error={Boolean(errors.nomor && touched.nomor)}
            errorText={Boolean(errors.nomor && touched.nomor) && errors.nomor}
          />
        </Col>
        <Col sm>
          <Input
            label="Qty. Job Mix"
            type="number"
            placeholder="Masukan Qty. Job Mix"
            value={parseInt(values.qty_jobmix)}
            min="0"
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              const val = e.target.value;
              const nilai = parseInt(dataJobMix.qty_sales_order) + parseInt(dataJobMix.qty_sisa);
              if (val > nilai) {
                setValues({ ...values, qty_jobmix: "" });
                setDataQty("")
              } else {
                setValues({ ...values, qty_jobmix: val });
                setDataQty(val)
              }
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default InfoSection;
