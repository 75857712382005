const RupiahConvert = value => {

  const convertToRupiah = () => {
    let rupiah = ''
    const convert = value.toString().split('').reverse().join('')

    for(var i = 0; i < convert.length; i++) if(i%3 === 0) rupiah += convert.substr(i,3)+'.'

    return rupiah.split('',rupiah.length-1).reverse().join('');
  }

  const withLableRupiah = () => {
    return `Rp${convertToRupiah()}`
  }

  const convertToNumber = () => {
    return parseInt(value.replace(/,.*|[^0-9]/g, ''), 10)
  }

  return {
    detail: withLableRupiah(),        // => Rp1.000.000
    default: convertToNumber(),       // => 1000000
    withoutLabel: convertToRupiah()   // => 1.000.000
  }
}

export default RupiahConvert