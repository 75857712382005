import React, {
    useState,
    useEffect
  } from 'react'
  import {
    useHistory,
    useLocation
  } from 'react-router-dom'
  import {
    Row,
    Col
  } from 'react-bootstrap'
  import {
    CRUDLayout,
    Alert,
    DataStatus,
    ActionButton,
    InputSearch,
    Pagination,
    Table,
    THead,
    TBody,
    Tr,
    ThFixed,
    TdFixed,
    Th,
    Td,
    BackButton
  } from 'components'
  import { PageNumber as TableNumber } from 'utilities'
  import {
    RealisasiJobMixApi
  } from 'api'
	import { IoAddOutline } from 'react-icons/io5'
  
  
  const ListRealisasiJobMix = ({ setNavbarTitle }) => {
    const history = useHistory()
    const location = useLocation()
    const [isPageLoading, setIsPageLoading] = useState(true)
    const [dataRealisasiProduksi, setDataRealisasiProduksi] = useState([])
    const [paginationConfig, setPaginationConfig] = useState({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0'
    })
    const [searchConfig, setSearchConfig] = useState({
      status: false,
      key: ''
    })
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: 'primary',
      text: ''
    })
  
    const getInitialData = () => {
      setIsPageLoading(true)
  
      RealisasiJobMixApi.page_jobmix({
        q: searchConfig.key,
        page: paginationConfig.page,
        per_page: paginationConfig.dataLength
      })
        .then(({ data }) => {
          setDataRealisasiProduksi(data.data)
          setPaginationConfig({
            ...paginationConfig,
            dataCount: data.data_count,
            totalPage: data.total_page
          })
        })
        .catch(err => {
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data gagal dimuat!'
          })
        })
        .finally(() => setIsPageLoading(false))
    }
  
    const checkAlert = () => {
      const locationState = location.state
  
      if (locationState) {
        if (locationState.alert) {
          setAlertConfig({
            show: locationState.alert.show,
            text: locationState.alert.text,
            variant: locationState.alert.variant
          })
        }
      }
    }
  
    const onInputSearchChange = e => {
      const key = e.target.value
  
      setSearchConfig({
        ...searchConfig,
        key: e.target.value
      })
      setPaginationConfig({
        page: '1',
        dataLength: '10',
        totalPage: '1',
        dataCount: '0'
      })
      setAlertConfig({
        show: key ? true : false,
        variant: 'primary',
        text: 'Hasil dari pencarian: ' + key
      })
    }
  
    useEffect(() => {
      setNavbarTitle('List Realisasi Job Mix')
      getInitialData()
      checkAlert()
  
      return () => {
        setIsPageLoading(false)
      }

      // eslint-disable-next-line
    }, [setNavbarTitle, searchConfig.key, paginationConfig.page, paginationConfig.dataLength])
  
    const PageContent = () => {
      const DataTable = () => (
        <Table>
          <THead>
            <Tr>
              <ThFixed>No.</ThFixed>
              <ThFixed>No. Sales Order</ThFixed>
              <ThFixed>No. Job Mix</ThFixed>
              <Th>Customer</Th>
              <Th>Barang Jadi</Th>
              <Th>Qty. Job Mix</Th>
              <Th>Qty. Telah Realisasi</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {dataRealisasiProduksi.map((val, index) => (
              <Tr key={index}>
                <Td className="text-center">{TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}</Td>
                <Td>{val.no_sales_order ?? '-'}</Td>
                <Td>{val.no_jobmix ?? '-'}</Td>
                <Td>{val.nama_customer ? val.nama_customer : '-'}</Td>
                <Td>{val.nama_item ? val.nama_item : '-'}</Td>
                <Td className="text-right">{val.qty_jobmix} {val.kode_satuan}</Td>
                <Td className="text-right">{val.qty_realisasi} {val.kode_satuan}</Td>
                <TdFixed>
                  <ActionButton
                    size="sm"
                    className="col"
										tooltip
                  	tooltipText="Realisasi Job Mix"
                    onClick={() => history.push({
                      pathname: '/transaksi/realisasi-job-mix/realisasi/' + val.id_jobmix,
                      state: {
                        dataJobmix: val
                      }
                    })}
                  >
										<IoAddOutline />
									</ActionButton>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      )
  
      if (!dataRealisasiProduksi || dataRealisasiProduksi.length < 1) {
        return <DataStatus text="Tidak ada data" />
      }
  
      return (
        <>
          <DataTable />
          {!searchConfig.status &&
            <Pagination
              dataLength={paginationConfig.dataLength}
              dataNumber={paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1}
              dataPage={paginationConfig.dataCount < paginationConfig.dataLength ? paginationConfig.dataCount : paginationConfig.page * paginationConfig.dataLength}
              dataCount={paginationConfig.dataCount}
              currentPage={paginationConfig.page}
              totalPage={paginationConfig.totalPage}
              onPaginationChange={({ selected }) => setPaginationConfig({
                ...paginationConfig,
                page: selected + 1
              })}
              onDataLengthChange={(e) => setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value
              })}
            />
          }
        </>
      )
    }
  
    return (
      <CRUDLayout>
        {/* head section */}
        <CRUDLayout.Head>
          <CRUDLayout.HeadSearchSection>
            <Row className="mb-2">
              <Col md={8}>
                <InputSearch
                  value={searchConfig.key}
                  onChange={onInputSearchChange}
                />
              </Col>
            </Row>
          </CRUDLayout.HeadSearchSection>
          <CRUDLayout.HeadButtonSection>
            <BackButton onClick={() => history.goBack()} />
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>
  
        {/* alert */}
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => setAlertConfig({
            ...alertConfig,
            show: false
          })}
        />
  
        {/* content */}
        {isPageLoading
          ? <DataStatus
            loading={true}
            text="Memuat data . . ."
          />
          : <PageContent />
        }
      </CRUDLayout>
    )
  }
  
  export default ListRealisasiJobMix