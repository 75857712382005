import React, { useState, useEffect } from "react";
import { Row, Card, Nav } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus
} from "components";
import { TabInfo, TabGambar, TabFile } from './Section/Tabs'
import { TableSection } from "./Section";
import { RegistrasiBarangJadiApi } from "api";

const UbahRegistrasiBarangJadi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_item_atribut } = useParams();

  const [infoTabs, setInfoTabs] = useState('info')
  const [dropdown, setDropdown] = useState({})
  const [dataGambar, setDataGambar] = useState([])
  const [dataFile, setDataFile] = useState([])
  const [dataAnalisa, setDataAnalisa] = useState([])
  const [dataBarangJadi, setDataBarangJadi] = useState({})
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const tabsConfig = [
    {
      tab: "info",
      label: "Informasi Umum",
      component: ({ ...rest }) => <TabInfo {...rest} />
    },
    {
      tab: 'gambar',
      label: 'Gambar',
      component: ({ ...rest }) => <TabGambar {...rest} />
    },
    {
      tab: 'file',
      label: 'File',
      component: ({ ...rest }) => <TabFile {...rest} />
    }
  ]

  const onTabsChangeHandler = (e, newPage) => {
    e.preventDefault();
    setInfoTabs(newPage);
  };

  const checkTypeAndStoreFile = data => {
    let gambar = []
    let file = []

    data.map(val => {
      const map = {
        link: val.path_gambar,
        id: val.id_item_buaso_gambar_barang_jadi,
        nama: '',
        data: null
      }

      const generalFileType = map.link.split('.').pop()
      const fileName = map.link.split('/').pop()

      // Check apakah data adalah gambar
      if (['jpg', 'png', 'gif', 'jpeg'].includes(generalFileType)) {
        return gambar.push({ ...map, nama: fileName })
      }

      return file.push({ ...map, nama: fileName })
    })

    setDataGambar(gambar)
    setDataFile(file)
  }

  const getInitialData = () => {
    setIsPageLoading(true)

    let res_id_kelompok = ''

    Axios.all([
      RegistrasiBarangJadiApi.single({ id_item_atribut }),
      RegistrasiBarangJadiApi.dropdown({ tipe: 'item_buaso' }),
      RegistrasiBarangJadiApi.dropdown({ tipe: "kelompok" }),
      RegistrasiBarangJadiApi.dropdown({ tipe: "pabrikan" }),
      RegistrasiBarangJadiApi.dropdown({ tipe: "satuan" }),
      RegistrasiBarangJadiApi.dropdown({ tipe: "item_aset" }),
      RegistrasiBarangJadiApi.dropdown({ tipe: 'barang_jadi' }),
    ])
      .then(Axios.spread((resSingle, resBuaso, resKelompok, resPabrikan, resSatuan, resItem_aset, resAnalisa) => {
        res_id_kelompok = resSingle?.data?.data?.id_kelompok ?? ''

        const data = resSingle?.data?.data ?? {}
        const item_buaso = resBuaso?.data?.data ?? []
        const kelompok = resKelompok?.data?.data?.map(item => Object({ ...item, value: item.id_kelompok, label: item.nama_kelompok }))
        const pabrikan = resPabrikan?.data?.data?.map(item => Object({ ...item, value: item.id_pabrikan, label: item.nama_pabrikan }))
        const satuan = resSatuan?.data?.data?.map(item => Object({ ...item, value: item.id_satuan, label: item.nama_satuan }))
        const item_aset = resItem_aset?.data?.data ?? []
        const analisa_barang_jadi = resAnalisa?.data?.data?.map(item => Object({
          value: item.id_item_buaso,
          label: item.nama_gabungan,
          id_item_atribut: item.id_item_atribut,
          id_item_buaso: item.id_item_buaso,
          nama_item: item.nama_item,
          nama_varian: item.nama_varian,
          nama_gabungan: item.nama_gabungan
        }))

        setDataBarangJadi(data ?? {})
        setDataAnalisa(data?.analisa ?? [])
        checkTypeAndStoreFile(data?.path_gambar ?? [])
        setDropdown({
          item_buaso,
          kelompok,
          pabrikan,
          satuan,
          item_aset,
          analisa_barang_jadi
        })
      }))
      .then(() => {
        RegistrasiBarangJadiApi.dropdown({ tipe: "jenis", id_kelompok: res_id_kelompok })
          .then(res => {
            const jenis = res?.data?.data?.map(item => Object({ ...item, value: item.id_jenis, label: item.nama_jenis }))
            setDropdown(prev => ({ ...prev, jenis }))
          })
      })
      .catch((err) => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  }

  const handleKelompokOnChange = (id_kelompok) => {
    RegistrasiBarangJadiApi.dropdown({ tipe: "jenis", id_kelompok })
      .then(({ data }) => {
        const jenis = data?.data?.map(item => Object({ ...item, value: item.id_jenis, label: item.nama_jenis }))
        setDropdown({ ...dropdown, jenis })
      })
      .catch(() => setAlertConfig({
        show: true,
        variant: "danger",
        text: "Data Jenis Barang Jadi gagal dimuat!",
      }));
  }

  const formInitialValues = {
    id_buaso: "1",
    id_item_atribut: dataBarangJadi?.id_item_atribut,
    id_item_buaso: dataBarangJadi?.id_item_buaso ?? "",
    nama_item: dataBarangJadi?.nama_item ?? "",
    nama_varian: dataBarangJadi?.nama_varian ?? "",
    kode_item: dataBarangJadi?.kode_item ?? "",
    id_satuan: dataBarangJadi?.id_satuan_pakai ?? "",
    id_kelompok: dataBarangJadi?.id_kelompok ?? "",
    id_jenis: dataBarangJadi?.id_jenis ?? "",
    id_pabrikan: dataBarangJadi?.id_pabrikan ?? "",
    id_satuan_jual: dataBarangJadi?.id_satuan_jual ?? "",
    id_satuan_beli: dataBarangJadi?.id_satuan_beli ?? "",
    link_referensi: dataBarangJadi?.link_referensi ?? "",
    uraian: dataBarangJadi?.uraian ?? "",
  };
  const formValidationSchema = Yup.object().shape({
    nama_item: Yup.string()
      .required("Nama Barang Jadi wajib diisi")
      .test({
        name: 'nama_item',
        message: "Nama Barang Jadi & Nama Varian telah digunakan",
        test: (value, all) => value === dataBarangJadi?.nama_item && all?.parent?.nama_varian === dataBarangJadi?.nama_varian ? true : RegistrasiBarangJadiApi.cek_nama({ nama_item_lc: value ? value.toLowerCase() : "", nama_varian_lc: all?.parent?.nama_varian ? all.parent.nama_varian.toLowerCase() : "" }).then(() => true).catch(() => false)
      }),
    id_satuan: Yup.string().required("Satuan Pakai wajib diisi"),
    id_kelompok: Yup.string().required("Kelompok Barang Jadi wajib diisi"),
    id_jenis: Yup.string().required("Jenis Barang Jadi wajib diisi"),
    id_pabrikan: Yup.string().required("Pabrikan wajib diisi"),
    id_satuan_jual: Yup.string().required("Satuan Jual wajib diisi"),
    id_satuan_beli: Yup.string().required("Satuan Beli wajib diisi"),
    link_referensi: Yup.string().required("Link Referensi wajib diisi"),
  });
  const formSubmitHandler = (values, { setSubmitting }) => {
    const mergeFile = [...dataGambar.filter(val => val.data), ...dataFile.filter(val => val.data)]
    const prevGambar = dataGambar.filter(val => !val.data).map(val => val.link.split('/').filter((val, index) => index > 2).join('/'))
    const prevFile = dataFile.filter(val => !val.data).map(val => val.link.split('/').filter((val, index) => index > 2).join('/'))
    const mergePrevFile = [...prevFile, ...prevGambar] // Gabungan data dari state gambar dan file yang telah di filter menjadi link untuk disimpan ke database
    const formData = new FormData();

    mergeFile?.map((val, index) => formData.append(`file_${index}`, val.data))

    RegistrasiBarangJadiApi.uploadFileMultiple(formData)
      .then((res) => {
        const fileResponse = res?.data?.data
        const path_gambar = Object.values(fileResponse)
        const finalValues = {
          ...values,
          path_gambar: [...path_gambar, ...mergePrevFile],
          analisa_barang_jadi: dataAnalisa
        }

        RegistrasiBarangJadiApi.update(finalValues)
          .then(() => history.push("/master/registrasi-barang-jadi", {
            alert: {
              show: true,
              variant: "primary",
              text: "Data berhasil ditambah!",
            }
          }))
          .catch(() => setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data gagal disimpan!'
          }))
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data & file gagal disimpan!'
        })
      })
      .finally(() => setSubmitting(false))
  };

  useEffect(() => {
    setNavbarTitle("Barang Jadi");
    getInitialData();
  }, [])

  return (
    <>
      <Row className="px-3 mb-2 d-flex justify-content-between align-items-center">
        <b>Ubah Barang Jadi</b>
        <BackButton onClick={() => history.goBack()} />
      </Row>

      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })} />

      {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." />
        : isFetchingFailed ? <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
          : <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}>
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Card className="pb-3 mt-3">
                  <Card.Header>
                    <Nav variant="tabs" defaultActiveKey={`#${infoTabs}`}>
                      {tabsConfig.map((val, index) => (
                        <Nav.Item key={`${index}-${val.label}`}>
                          <Nav.Link href={`#${val.tab}`} onClick={(e) => onTabsChangeHandler(e, val.tab)}>
                            {val.label}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </Card.Header>

                  <Card.Body style={{ minHeight: 490 }}>
                    {tabsConfig.map(({ tab, component: Component }, index) => tab === infoTabs &&
                      <Component
                        key={index}
                        formik={formik}
                        dropdown={dropdown}
                        dataFile={dataFile}
                        dataGambar={dataGambar}
                        setDataFile={setDataFile}
                        setDataGambar={setDataGambar}
                        handleKelompokOnChange={handleKelompokOnChange} />
                    )}
                  </Card.Body>
                </Card>

                <TableSection
                  dropdown={dropdown}
                  dataAnalisa={dataAnalisa}
                  setDataAnalisa={setDataAnalisa}
                  setAlertConfig={setAlertConfig} />

                <div className='d-flex justify-content-end align-items-center mt-3'>
                  <ActionButton
                    variant='success'
                    text='Ubah Data'
                    type='submit'
                    loading={formik.isSubmitting} />
                </div>
              </form>
            )}
          </Formik>
      }
    </>
  )
};

export default UbahRegistrasiBarangJadi;
