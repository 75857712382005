// React
import { useState, useEffect } from 'react'

// Componet
import { Card, ModalBody, Row, Col } from 'react-bootstrap'
import {
  DataStatus, Modal, Table, ThFixed, 
  Th, Td
} from 'components'

// Icon
import { IoCheckboxOutline } from 'react-icons/io5'

// API
import { JobMixApi } from 'api'

const PabrikSection = ({dataPabrik, selectedPabrik, onPabrikChange}) => {
  const [modalPabrikConfig, setModalPabrikConfig] = useState({
    show: false,
    data: {}
  })

  const PabrikCard = ({data, selected, onClick}) => {
    const dataGudang = data.gudang ?? []

    const InfoItem = ({ label, children }) => (
      <Row>
        <Col md="4" style={{ fontSize: 14 }}>{ label }</Col>
        <Col md style={{ fontSize: 14 }}>{children}</Col>
      </Row>
    )

    const PabrikCardTitle = () => (
      <div className="d-flex justify-content-between align-items-center p-2 border-bottom">
        <b className={`${selected ? 'text-primary' : ''}`}>{data.nama_pabrik}</b>
        {selected && <IoCheckboxOutline 
          className="text-primary" 
          size={20}
        />}
      </div>
    )

    const PabrikCardBody = () => (
      <div className="p-3">
        <InfoItem label="Kode Pabrik">: {data.kode_pabrik}</InfoItem>
        <InfoItem label="Jenis Pabrik">: {data.jenis_pabrik}</InfoItem>
        <InfoItem label="List Gudang"></InfoItem>

        {dataGudang && dataGudang.length > 0
          ? (
            dataGudang.map(val => (
              <div 
                style={{fontSize: 13}} 
                className="text-primary text-underline"
                onClick={e => {
                  e.stopPropagation()
                  setModalPabrikConfig({
                    show: true,
                    data: {
                      ...data, 
                      id_gudang: val.id_gudang,
                      nama_gudang: val.nama_gudang
                    },
                  })
                }}
              >
                {val.nama_gudang}
              </div>
            ))
          ) : <DataStatus text="Tidak ada Gudang" />
        }
      </div>
    )

    return (
      <Card 
        className={`col-10 col-sm-6 col-md-5 col-lg-4 mr-3 p-0 ${data.status_jobmix !== 'Available' || dataGudang.length === 0 ? 'border border-danger' : selected ? 'border border-primary' : ''}`}
        style={{
          cursor: 'pointer'
        }}
        onClick={onClick}
      >
        <Card.Body className="p-0">
          <PabrikCardTitle />  
          <PabrikCardBody />
        </Card.Body>
      </Card>
    )
  }

  const ModalPabrik = () => {
    const [dataBahan, setDataBahan] = useState([])
    const [dataAlatMesin, setDataAlatMesin] = useState([])
    // const [selectedTabs, setSelectedTabs] = useState('bahan') 
    const [modalFetchingStatus, setModalFetchingStatus] = useState({
      loading: true,
      success: false
    })
    
    const getInitialDataModal = () => {
      setModalFetchingStatus({
        loading: true,
        success: false
      })

      JobMixApi.getDetailGudang({id_gudang: modalPabrikConfig.data.id_gudang, id_pabrik: modalPabrikConfig.data.id_pabrik})
        .then(res => {
          const data = res.data.data

          setDataBahan(data.bahan ?? [])
          setDataAlatMesin(data.alat_mesin ?? [])
          setModalFetchingStatus({
            loading: false,
            success: true
          })
        })
        .catch(() => {
          setModalFetchingStatus({
            loading: false,
            success: false
          })
        })
    }

    const TabBahan = () => {
      const bahan = dataBahan.filter(data => parseInt(data.jumlah) > 0)

      return (
        <div className="overflow-auto">
          <Table className="m-0">
            <thead className="bg-light">
              <tr>
                <ThFixed>No</ThFixed>
                <Th width={100}>Kode Bahan</Th>
                <Th>Item Bahan</Th>
                <Th width={150}>Stok Bahan</Th>
                <Th width={150}>Satuan</Th>
              </tr>
            </thead>
            <tbody>
              {bahan && bahan.length > 0
                ? bahan.map((val, index) => (
                    <tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td>{val.kode_item ?? '-'}</Td>
                      <Td>{val.nama_item ?? '-'}</Td>
                      <Td textRight>{val.jumlah ? parseFloat(val.jumlah) : 0}</Td>
                      <Td>{val.nama_satuan ?? '-'}</Td>
                    </tr>
                  ))
                : <tr>
                    <Td colSpan={5}>
                      <DataStatus text="Tidak ada data" />
                    </Td>
                  </tr>
              }
            </tbody>
          </Table>
        </div>
      )
    }

    // const TabAlatMesin = () => {
    //   return (
    //     <div className="overflow-auto" style={{height: '60vh'}}>
    //       <Table>
    //         <thead className="bg-light">
    //           <tr>
    //             <ThFixed>No</ThFixed>
    //             <Th noPadding width={100}>Kode Alat & Mesin</Th>
    //             <Th>Item Alat & Mesin</Th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {dataAlatMesin && dataAlatMesin.length > 0
    //             ? dataAlatMesin.map((val, index) => (
    //                 <tr key={index}>
    //                   <Td>{index + 1}</Td>
    //                   <Td>{val.kode_item_aset}</Td>
    //                   <Td>{val.nama_item_aset}</Td>
    //                 </tr>
    //               ))
    //             : <tr>
    //                 <Td colSpan={3}>
    //                   <DataStatus text="Tidak ada data" />
    //                 </Td>
    //               </tr>
    //           }
    //         </tbody>
    //       </Table>
    //     </div>
    //   )
    // }

    // const tabsConfig = [
    //   {
    //     tab: 'bahan',
    //     label: 'Bahan',
    //     component: () => <TabBahan />
    //   },
    //   {
    //     tab: 'alat',
    //     label: 'Alat Mesin',
    //     component: () => <TabAlatMesin />
    //   },
    // ]

    useEffect(() => {
      getInitialDataModal()
    }, [])

    if (modalFetchingStatus.loading || !modalFetchingStatus.success) {
      return (
        <Modal
          closeButton
          size="lg"
          title={`Detail ${modalPabrikConfig?.data?.nama_gudang} (${modalPabrikConfig?.data?.nama_pabrik}) `}
          show={modalPabrikConfig}
          onHide={() => setModalPabrikConfig({
            show: false,
            data: {}
          })}
        >
          <ModalBody>
            {modalFetchingStatus.loading 
              ? <DataStatus loading text="Memuat data . . ." />
              : !modalFetchingStatus.success && <DataStatus text="Data gagal dimuat!" /> 
            }
          </ModalBody>
        </Modal>
      )
    }

    return (
      <Modal
        closeButton
        scrollable
        size="lg"
        title={`Detail ${modalPabrikConfig?.data?.nama_gudang} (${modalPabrikConfig?.data?.nama_pabrik}) `}
        show={modalPabrikConfig}
        onHide={() => setModalPabrikConfig({
          show: false,
          data: {}
        })}
      >
          <TabBahan />
        {/* <Card>
        </Card> */}
      </Modal>
    )
  }
  
  return (
    <div className="mt-4 overflow-hidden">
      <h6 className="px-2 font-weight-bold">Data Pabrik</h6>
      {dataPabrik && dataPabrik.length > 0
        ? <div className="d-flex overflow-auto my-1 pb-4">
            {dataPabrik.map((val, index) => (
              <PabrikCard 
                key={index}
                data={val} 
                selected={selectedPabrik === val && true}
                onClick={() => {
                  val.status_jobmix === "Available" && val.gudang.length > 0 && onPabrikChange(val)
                }}
              />
            ))}
          </div>
        : <div className="py-4">
            <DataStatus text="Tidak ada data pabrik" />
          </div>
      }
      {modalPabrikConfig.show && <ModalPabrik />}
    </div>
  )
}

export default PabrikSection
