import Services from "services";

class CloseJobMixApi {
    get(params) {
        return Services.get("/jobmix/page", { params });
    }

    getSingle(params) {
        return Services.get("/jobmix/single", { params });
    }

    getListBDP(params) {
        return Services.get('jobmix_close/list_bdp', { params })
    }

    getNo(params) {
        return Services.get("/jobmix_close/no_baru", { params });
    }

    save(data) {
        return Services.put("/jobmix_close", data);
    }
}

export default new CloseJobMixApi();
