import {
  useState,
  useEffect
} from 'react'
import { 
  ModalBody, 
  ModalFooter,
  Row,
  Col
} from 'react-bootstrap'
import { 
  IoAddOutline 
} from 'react-icons/io5'
import {
  Formik
} from 'formik'
import * as Yup from 'yup'
import {
  DataStatus,
  Modal,
  ActionButton,
  Table, 
  Td, 
  TdFixed, 
  Th,
  ThFixed,
  InfoItemHorizontal,
  DatePicker,
  Input,
  PopUpAlert
} from 'components'
import {
  DateConvert,
  RupiahConvert
} from 'utilities'

const TabAlatMesinSection = ({selectedPabrik, dataAlatMesin, dataPermintaanAlatMesin, dataKetersediaanAlatMesin, setDataPermintaanAlatMesin}) => {
  const [modalPermintaanConfig, setModalPermintaanConfig] = useState({
    show: false,
    data: {}
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    type: '',
    title: '',
    text: '',
    hide: () => setAlertConfig({...alertConfig, show: false})
  })

  const TableIngredient = () => {
    const getTotal = (qty, harga) => {
      const parseQty = qty ? parseFloat(qty) : 0
      const parseHarga = harga ? parseInt(harga) : 0

      return parseInt(parseHarga * parseQty)
    }

    const getGrandTotal = () => dataAlatMesin.reduce((prev, current) => {
      const total = getTotal(current.qty_analisa, current.harga)

      return prev + total
    }, 0)

    const checkKetersediaan = (idItem) => {
      const filter = dataKetersediaanAlatMesin.find(val => val.id_item === idItem)

      if (!filter) {
        return false
      }

      const check = filter.ketersediaan === true

      if (check) {
        return true
      }

      return false
    }

    return (
      <div>
        <div className="px-1 pb-2">
          <b>List Ingredient</b>
        </div>
        <Table responsive>
          <thead className="bg-light">
            <tr>
              <ThFixed>No.</ThFixed>
              <Th noPadding width={90}>Kode Alat & Mesin</Th>
              <Th>Item Alat & Mesin</Th>
              <Th noPadding width={50}>Qty. Analisa</Th>
              <Th noPadding width={150}>Harga Satuan</Th>
              <Th noPadding width={150}>Sub Total</Th>
            </tr>
          </thead>
          <tbody>
            {dataAlatMesin && dataAlatMesin.length > 0
              ? dataAlatMesin.map((val, index) => ( 
                <tr>
                  <Td textCenter>{index + 1}</Td>
                  <Td>{val.kode_item}</Td>
                  <Td>{val.nama_item}</Td>
                  <Td textRight>{val.qty_analisa} {val.kode_satuan}</Td>
                  <Td textRight>{RupiahConvert(String(val.harga)).detail}</Td>
                  <Td textRight>{RupiahConvert(String(getTotal(val.qty_analisa, val.harga))).detail}</Td>
                </tr>
              ))
              : <tr>
                  <Td colSpan={6}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </tr>
            }
          </tbody>
          <tfoot>
            <tr className="bg-light">
              <Td textRight colSpan={5}>
                <b style={{fontSize: 14}}>Total</b>
              </Td>
              <Td textRight>
                <b style={{fontSize: 14}}>
                  {RupiahConvert(getGrandTotal().toString()).detail}
                </b>
              </Td>
            </tr>
          </tfoot>
        </Table>
      </div>
    )
  }

  return (
    <>  
      <TableIngredient />
      <PopUpAlert 
        show={alertConfig.show}
        onConfirm={alertConfig.hide}
        onHide={alertConfig.hide}
        title={alertConfig.title}
        text={alertConfig.text}
        type={alertConfig.type}
      />
    </>
  )
}

export default TabAlatMesinSection
