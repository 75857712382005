import React, { 
  useState, 
  useEffect 
} from "react"
import { 
  useHistory, 
  useLocation 
} from "react-router-dom"
import { 
  Row, 
  Col 
} from "react-bootstrap"
import {
  IoAddOutline,
  IoEyeOutline
} from 'react-icons/io5'
import _ from "lodash"
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  UpdateButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  TdFixed,
  CreateButton,
  BackButton
} from "components"
import { 
  DateConvert, 
  TableNumber 
} from "utilities"
import { 
  JobMixApi
} from "api"

const ListSalesOrderJobMix = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataSalesOrder, setDataSalesOrder] = useState([])
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    JobMixApi.getSalesOrder({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setDataSalesOrder(data.data)
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        })
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setPaginationConfig({
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    })
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
  }

  const generateStatusApproval = (status) => {
    const newStatus = status?.toUpperCase()
    if (newStatus === "REJ") {
      return "REJECTED"
    }

    if (newStatus === "REV") {
      return "REVISI"
    }

    if (newStatus === "APP") {
      return "APPROVED"
    }

    if (newStatus === "VER") {
      return "VERIFIED"
    }

    return "PENDING"
  }

  useEffect(() => {
    setNavbarTitle("List Sales Order Yang Siap Dibuatkan Job Mix")
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
  ])

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <Th noPadding width={80}>Tgl. Sales Order</Th>
            <Th noPadding width={120}>No. Sales Order</Th>
            <Th>Nama Customer</Th>
            <Th>Nama Barang Jadi</Th>
            <Th noPadding width={80}>Qty Sales Order</Th>
            <Th noPadding width={80}>Qty Telah Diproduksi</Th>
            <ThFixed>Aksi</ThFixed>
          </tr>
        </thead>
        <tbody>
          {dataSalesOrder.map((val, index) => (
            <tr key={index}>
              <Td textCenter>{TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}</Td>
              <Td>{val.tgl_sales_order ? DateConvert(new Date(val.tgl_sales_order)).defaultDMY : "-"}</Td>
              <Td>{val.no_sales_order ?? "-"}</Td>
              <Td>{val.nama_customer ?? "-"}</Td>
              <Td>{val.nama_barang ?? "-"}</Td>
              <Td>{val.qty_item ? `${parseFloat(val.qty_item)} ${val.kode_satuan ?? ''}` : '-'}</Td>
              <Td>{val.qty_diproduksi ? `${parseFloat(val.qty_diproduksi)} ${val.kode_satuan ?? ''}` : '-'}</Td>
              <Td>
                <ActionButton 
                  size="sm"
                  tooltip
                  tooltipText="Register Job Mix"
                  onClick={() => history.push('/transaksi/job-mix/tambah/' + val.id_sales_order_detail, {
                    dataSalesOrder: {
                      id_sales_order: val.id_sales_order,
                      no_sales_order: val.no_sales_order,
                      id_item: val.id_item_atribut,
                      nama_item: val.nama_barang,
                      qty: val.qty_item,
                      customer: val.nama_customer
                    }
                  })}
                >
                  <IoAddOutline />
                </ActionButton>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    )

    if (!dataSalesOrder || dataSalesOrder.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}
    </CRUDLayout>
  )
}

export default ListSalesOrderJobMix
