import React, { useState, useEffect } from "react"
import { Modal, Spinner, ButtonGroup } from "react-bootstrap"
import { useFormik } from "formik"
import * as Yup from 'yup'
import {
  IoTrashOutline,
  IoPencilOutline,
  IoAddOutline,
  IoCloseOutline,
  IoCheckmark,
} from "react-icons/io5"
import { Alert, SelectSearch, Input, ActionButton, Table, Td } from "components"
import { RupiahConvert } from "utilities"

const TableAnalisa = ({ tab, id_buaso, data, setData, dataSelect, tableHead, type = 'CREATE' }) => {
  const defaultIdKelompok = id_buaso === '5' && tab === 'BOK' ? '13' : ''
  const [processedData, setProcessedData] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [indexEdit, setIndexEdit] = useState('')
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const [modalConfig, setModalConfig] = useState({
    show: false,
  })
  const tableStyling = {
    padding: "0.5px",
    fontSize: "14px",
    verticalAlign: "middle",
  }
  const mapDataSelect = dataSelect?.map(item => Object({
    ...item,
    value: item.id_item_buaso ?? item.id_item_aset,
    label: `${item.kode_item} - ${item.nama_item}`
  }))

  const filterData = (data) => {
    if (tab === 'OVERHEAD') return data?.filter(item => item.id_buaso === id_buaso && item.id_kelompok !== '13')
    return data?.filter(item => item.id_buaso === id_buaso)
  }
  const filteredData = filterData(data)
  const getTotal = () => filteredData?.reduce((total, { sub_total }) => total + parseInt(sub_total ?? 0), 0)

  // const sortByName = (a, b) => {
  //   if (a.nama_item < b.nama_item) {
  //     return - 1
  //   }
  //   if (a.nama_item > b.nama_item) {
  //     return 1
  //   }
    
  //   return 0
  // }
  
  const FormSection = ({ type }) => {
    const decimalConvert = (value) => {
      const newValue = value.toString().replace(/[^0-9\.]/g, "")
      const convert = newValue.substring(0, 5)

      return convert
    }

    const formInitialValues = {
      id_buaso: type === "update" ? processedData.id_buaso : "",
      id_kelompok: type === "update" ? processedData.id_kelompok : defaultIdKelompok,
      id_item_atribut: type === "update" ? processedData.id_item_atribut : "",
      kode_item: type === "update" ? processedData.kode_item : "",
      nama_item: type === "update" ? processedData.nama_item : "",
      id_item_buaso: type === "update" ? processedData.id_item_buaso : "",
      qty: type === "update" ? processedData.qty : 0,
      kode_satuan: type === "update" ? processedData.kode_satuan : "",
      harga: type === "update" ? processedData.harga : 0,
    }
    const formValidationSchema = Yup.object().shape({
      id_item_buaso: Yup.string().required(),
      qty: Yup.string().required(),
      harga: Yup.string().required(),
    })
    const formSubmitHandler = (values, { setSubmitting }) => {
      let newData = []

      if (type === "update") {
        const update = data?.map((item, index) => Boolean(JSON.stringify(item) === JSON.stringify(processedData)) ? values : item)
        newData = update
      } else {
        newData = [...data, values]
      }

      setTimeout(() => {
        setAlertConfig({
          show: true,
          variant: "primary",
          text: "Data berhasil ditambah!",
        })

        type === "update" ? setData(newData) : setData(newData)
        setIsEdit(false)
        setSubmitting(false)
        setProcessedData({})
      }, 300)
    }
    const formik = useFormik({
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    })

    const { values, errors, touched, setValues, setFieldValue, handleSubmit, isSubmitting } = formik

    return (
      <tr>
        <Td />
        <Td colSpan={2}>
          <SelectSearch
            noMargin
            placeholder={`Pilih ${tableHead?.column3 ?? 'Item Buaso'}`}
            option={mapDataSelect ?? []}
            defaultValue={values.id_item_buaso ? mapDataSelect?.find((val) => val.value === values.id_item_buaso) : ""}
            onChange={(val) => {
              setValues({
                ...values,
                id_buaso: val.id_buaso,
                id_item_buaso: val.id_item_buaso ?? val.id_item_aset,
                id_item_atribut: val.id_item_atribut,
                kode_item: val.kode_item,
                nama_item: val.nama_item,
                kode_satuan: val.kode_satuan,
                harga: val.harga,
              })
            }}
            error={Boolean(errors.id_barang_jadi && touched.id_barang_jadi)}
          />
        </Td>
        <Td>
          <Input
            noMargin
            className="text-right"
            placeholder="Harga"
            value={RupiahConvert(String(parseInt(values.harga || 0))).detail}
            onChange={(e) => {
              // const value = decimalConvert(e.target.value)
              const value = RupiahConvert(e.target.value).default
              setFieldValue("harga", value)
            }}
            error={Boolean(errors.harga && touched.harga)}
          />
        </Td>
        {/* <Td textRight>{RupiahConvert(String(parseFloat(values.harga_satuan))).detail}</Td> */}
        <Td width={80}>
          <Input
            noMargin
            placeholder="Qty"
            className='text-right'
            value={values.qty}
            onChange={(e) => {
              const value = decimalConvert(e.target.value)
              setFieldValue("qty", value)
            }}
            error={Boolean(errors.qty && touched.qty)}
          />
        </Td>
        <Td>{values.kode_satuan ?? "-"}</Td>
        <Td textRight>{RupiahConvert(String(parseInt(parseFloat(values.qty ?? 0) * parseFloat(values.harga ?? 0)))).detail}
        </Td>
        <Td>
          {type === "update" ? (
            <ButtonGroup style={{ width: "100%" }}>
              <ActionButton
                size="sm"
                variant="outline-danger"
                onClick={() => {
                  setIsEdit(false)
                  setProcessedData({})
                }}
                disable={isSubmitting}
              >
                <IoCloseOutline />
              </ActionButton>

              <ActionButton
                size="sm"
                variant="outline-success"
                onClick={handleSubmit}
                disable={isSubmitting}
              >
                {isSubmitting ? <Spinner animation="border" size="sm" /> : <IoCheckmark />}
              </ActionButton>
            </ButtonGroup>
          ) : (
            <ActionButton size="sm" className="col" onClick={handleSubmit} disable={isSubmitting}>
              {isSubmitting ? <Spinner animation="border" size="sm" /> : <IoAddOutline />}
            </ActionButton>
          )}
        </Td>
      </tr >
    )
  }

  const ListDataTable = ({ index, val }) => {
    return (
      <tr key={index}>
        <td style={tableStyling} className='text-center' >{index + 1}</td>
        <td style={tableStyling} className="px-2">{val.kode_item}</td>
        <td style={tableStyling} className="px-2">{val.nama_item}</td>
        <td style={tableStyling} className="text-right">{val.harga && RupiahConvert(parseInt(val.harga).toString()).detail}</td>
        <td style={tableStyling} className="text-right">{val.qty ? parseFloat(val.qty).toPrecision() : '0'}</td>
        <td style={tableStyling} className="px-2">{val.kode_satuan}</td>
        <td style={tableStyling} className="text-right">{RupiahConvert(String(parseInt(val.sub_total ?? 0))).detail}</td>
        {type !== 'DETAIL' && <td>
          <div className="btn-group p-0">
            <ActionButton
              size="sm"
              variant="success"
              text={<IoPencilOutline />}
              onClick={() => {
                setIsEdit(true)
                setIndexEdit(index)
                setProcessedData(val)
              }}
            />
            <ActionButton
              size="sm"
              variant="danger"
              text={<IoTrashOutline />}
              onClick={() => {
                setProcessedData(val)
                setModalConfig({
                  show: true,
                })
              }}
            />
          </div>
        </td>}
      </tr>
    )
  }

  const DeleteModal = () => {
    const [isDeleting, setIsDeleting] = useState(false)

    const deleteDataHandler = () => {
      setIsDeleting(true)

      const filterData = data?.filter((val) => JSON.stringify(val) !== JSON.stringify(processedData))

      setTimeout(() => {
        setData(filterData)
        setModalConfig({ ...modalConfig, show: false })
        setAlertConfig({
          show: true,
          variant: "primary",
          text: "Data berhasil dihapus!",
        })
      }, 300)
    }

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size="md"
        onHide={() => {
          setProcessedData({})
          setModalConfig({ show: false })
        }}>
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small><span className="text-danger">Hapus Data</span></small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5>
            <span>Apakah anda yakin menghapus data?</span>
          </h5>
          <small className="text-danger">Data yang dihapus tidak dapat dikembalikan!</small>
          <div className="d-flex justify-content-center mt-3">
            <ActionButton
              variant="outline-secondary"
              className="m-1"
              text="Batal"
              onClick={() => setModalConfig({ ...modalConfig, show: false })} />
            <ActionButton
              variant="danger"
              className="m-1"
              text="Hapus Data"
              loading={isDeleting}
              onClick={deleteDataHandler}
            />
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <>
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />
      <div className="table-responsive" style={{ minHeight: 300 }}>
        <table className="table table-bordered bg-white table-sm">
          <thead className="text-center bg-light">
            <tr>
              <th className="align-middle p-2" style={{ ...tableStyling, width: 10 }}>
                No.
              </th>
              <th className="align-middle p-2" style={{ ...tableStyling, width: 105 }}>
                Kode Item
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                {tableHead?.column3 ?? "Nama Item"}
              </th>
              <th className="align-middle p-2" style={{ ...tableStyling, width: 100 }}>
                {tableHead?.column5 ?? "Harga Satuan"}
              </th>
              <th className="align-middle p-2" style={{ ...tableStyling, width: 80 }}>
                Qty. Analisa
              </th>
              <th className="align-middle p-2" style={{ ...tableStyling, width: 100 }}>
                Satuan
              </th>
              <th className="align-middle p-2" style={{ ...tableStyling, width: 100 }}>
                Sub Total
              </th>
              {type !== 'DETAIL' && <th className="align-middle" style={{ width: "30px" }}>
                Aksi
              </th>}
            </tr>
          </thead>
          <tbody>
            {type !== 'DETAIL' && <FormSection type='create' />}
            {filteredData?.length > 0 ?
              filteredData.map((val, index) => {
                const qty = parseFloat(val?.qty ?? 0)
                const harga = parseFloat(val?.harga ?? 0)
                const sub_total = Math.round(qty * harga)
                val.sub_total = sub_total
                return isEdit && index === indexEdit ? <FormSection key={index} type='update' /> : <ListDataTable key={index} index={index} val={val} />
              })
              : <tr>
                <td colSpan={8} className="text-center">Tidak ada data</td>
              </tr>
            }
            {filteredData?.length > 0 && <tr className='bg-light'>
              <td colSpan={6} className="text-right p-2" style={tableStyling}>
                <b>Total Price : </b>
              </td>
              <td className="text-right" style={tableStyling}>
                <b>{RupiahConvert(String(getTotal())).detail}</b>
              </td>
              {type !== 'DETAIL' && <td />}
            </tr>}
          </tbody>
        </table>
      </div>
      {modalConfig.show && <DeleteModal />}
    </>
  )
}

export default TableAnalisa
