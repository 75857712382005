import React from 'react';
import { Table, Th, Td } from 'components'
import { RupiahConvert } from 'utilities';

const TableSummary = ({ grandTotal }) => {
  const dataSummary = [
    { nama_biaya: 'Bahan', subtotal: grandTotal().totalBahan },
    { nama_biaya: 'Upah', subtotal: grandTotal().totalUpah },
    { nama_biaya: 'Alat Mesin', subtotal: grandTotal().totalAlatMesin },
    { nama_biaya: 'Subkon', subtotal: grandTotal().totalSubkon },
    { nama_biaya: 'Overhead', subtotal: grandTotal().totalOverhead }
  ]
  return (
    <div className="table-responsive">
      <Table>
        <thead className="text-center bg-light">
          <tr>
            <Th>No.</Th>
            <Th>Nama Biaya</Th>
            <Th width={300}> Total Price (Rp)</Th>
          </tr>
        </thead>
        <tbody>
          {dataSummary?.map((val, index) => (
            <tr key={index}>
              <Td textCenter width={20}>{index + 1}</Td>
              <Td>{val.nama_biaya}</Td>
              <Td textRight>{RupiahConvert(String(parseInt(val.subtotal) ?? 0)).detail}</Td>
            </tr>
          ))
          }
          <tr className='bg-light'>
            <Td colSpan={2} className="p-2" textRight><b style={{ fontSize: 14 }}>Grand Total</b></Td>
            <Td textRight className="p-2"><b style={{ fontSize: 14 }}>{RupiahConvert(String(parseInt(grandTotal().grandTotal) ?? 0)).detail}</b></Td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
};

export default TableSummary;
