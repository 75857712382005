import {
  // Registrasi Barang Jadi
  RegistrasiBarangJadi,
  TambahBarangJadi,
  UbahBarangJadi,
  DetailBarangJadi,

  // Pabrik
  ListPabrik,
  CRUDPabrik,

  // Jenis Barang Jadi
  JenisBarangJadi,

  // Job Mix
  ListJobMix,
  ListSalesOrderJobMix,
  DetailJobMix,
  TambahJobMix,
  UbahJobMix,

  // Realisasi Job Mix
  ListRealisasiJobMix,
  RealisasiJobMix,
  RealisasiListJobMix,

  // Close Job Mix
  AddCloseJobMix,
  CloseJobMix,
  DetailCloseJobMix
} from "pages";

/* 
  MENU REQUIREMENTS
    > EXACT = OPTIONAL (TRUE/FALSE)
    > PATH  = REQUIRED
    > PAGE  = REQUIRED
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
*/

export default [
  // MASTER
  // Registrasi Barang Jadi
  {
    exact: true,
    path: "/master/registrasi-barang-jadi",
    page: RegistrasiBarangJadi,
    hak: ["SUPA", "PRD_MAS_BRJ"],
  },
  {
    exact: true,
    path: "/master/registrasi-barang-jadi/tambah",
    page: TambahBarangJadi,
    hak: ["SUPA", "PRD_MAS_BRJ"],
  },
  {
    exact: true,
    path: "/master/registrasi-barang-jadi/ubah/:id_item_atribut",
    page: UbahBarangJadi,
    hak: ["SUPA", "PRD_MAS_BRJ"],
  },
  {
    exact: true,
    path: "/master/registrasi-barang-jadi/detail/:id_item_atribut",
    page: DetailBarangJadi,
    hak: ["SUPA", "PRD_MAS_BRJ"],
  },

  // Pabrik
  {
    exact: true,
    path: "/master/pabrik",
    page: ListPabrik,
    hak: ["SUPA", "PRD_MAS_PAB"],
  },
  {
    exact: true,
    path: "/master/pabrik/:tipe",
    page: CRUDPabrik,
    hak: ["SUPA", "PRD_MAS_PAB"],
  },
  {
    exact: true,
    path: "/master/pabrik/:tipe/:id_pabrik",
    page: CRUDPabrik,
    hak: ["SUPA", "PRD_MAS_PAB"],
  },
  // JENIS BARANG JADI
  {
    exact: true,
    path: "/master/jenis-barang-jadi",
    page: JenisBarangJadi,
    hak: ["SUPA", "PRD_MAS_JBJ"],
  },

  // TRANSAKSI
  // Job Mix
  {
    exact: true,
    path: "/transaksi/job-mix",
    page: ListJobMix,
    hak: ["SUPA", "PRD_TRN_JBX"],
  },
  {
    exact: true,
    path: "/transaksi/job-mix/sales-order",
    page: ListSalesOrderJobMix,
    hak: ["SUPA", "PRD_TRN_JBX"],
  },
  {
    exact: true,
    path: "/transaksi/job-mix/detail/:id",
    page: DetailJobMix,
    hak: ["SUPA", "PRD_TRN_JBX"],
  },
  {
    exact: true,
    path: "/transaksi/job-mix/tambah/:id",
    page: TambahJobMix,
    hak: ["SUPA", "PRD_TRN_JBX"],
  },
  {
    exact: true,
    path: "/transaksi/job-mix/ubah/:id",
    page: UbahJobMix,
    hak: ["SUPA", "PRD_TRN_JBX"],
  },

  // Realisasi Job Mix
  {
    exact: true,
    path: "/transaksi/realisasi-job-mix",
    page: ListRealisasiJobMix,
    hak: ["SUPA", "PRD_TRN_REP"],
  },
  {
    exact: true,
    path: "/transaksi/realisasi-job-mix/:tipe/:id_jobmix",
    page: RealisasiJobMix,
    hak: ["SUPA", "PRD_TRN_REP"],
  },
  {
    exact: true,
    path: "/transaksi/realisasi-job-mix/list-job-mix",
    page: RealisasiListJobMix,
    hak: ["SUPA", "PRD_TRN_REP"],
  },

  // CLOSE JOB MIX
  {
    exact: true,
    path: "/transaksi/close-job-mix",
    page: CloseJobMix,
    hak: ["SUPA", "PRD_TRN_CLJ"],
  },
  {
    exact: true,
    path: "/transaksi/close-job-mix/tambah/:id",
    page: AddCloseJobMix,
    hak: ["SUPA", "PRD_TRN_CLJ"],
  },
  {
    exact: true,
    path: "/transaksi/close-job-mix/detail/:id",
    page: DetailCloseJobMix,
    hak: ["SUPA", "PRD_TRN_CLJ"],
  },
];
