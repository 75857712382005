import React, { useState, useEffect } from 'react'
import { Card, Nav, Row, Col } from 'react-bootstrap'

import { SelectSearch } from 'components'
import TableAnalisa from './table/TableAnalisa'
import TableSummary from './table/TableSummary'
import { RegistrasiBarangJadiApi } from 'api'

const TableSection = ({ dropdown, dataAnalisa, setDataAnalisa, setAlertConfig, type: TYPE }) => {
  const [selectedSalinData, setSelectedSalinData] = useState({ value: '', label: 'Tanpa Salinan' })
  const [tableTabs, setTableTabs] = useState('bahan')

  const dataSelectBahan = dropdown?.item_buaso?.filter(item => item.id_buaso === '1') ?? []
  const dataSelectUpah = dropdown?.item_buaso?.filter(item => item.id_buaso === '2') ?? []
  const dataSelectAlatMesin = dropdown?.item_aset ?? []
  const dataSelectSubkon = dropdown?.item_buaso?.filter(item => item.id_buaso === '4') ?? []
  const dataSelectOverhead = dropdown?.item_buaso?.filter(item => item.id_buaso === '5' && item.id_kelompok !== '13') ?? []

  const tabsConfig = [
    {
      tab: "bahan",
      label: "Bahan",
      component: () => (
        <TableAnalisa
          type={TYPE}
          id_buaso={'1'}
          data={dataAnalisa}
          setData={setDataAnalisa}
          dataSelect={dataSelectBahan}
          tableHead={{ column3: 'Item Bahan', column5: 'Harga Satuan' }}
        />
      ),
    },
    {
      tab: 'upah',
      label: 'Upah',
      component: () => (
        <TableAnalisa
          type={TYPE}
          id_buaso={'2'}
          data={dataAnalisa}
          setData={setDataAnalisa}
          dataSelect={dataSelectUpah}
          tableHead={{ column3: 'Item Upah', column5: 'Harga Satuan' }}
        />
      )
    },
    {
      tab: 'alatmesin',
      label: 'Alat & Mesin',
      component: () => (
        <TableAnalisa
          type={TYPE}
          id_buaso={'3'}
          data={dataAnalisa}
          setData={setDataAnalisa}
          dataSelect={dataSelectAlatMesin}
          tableHead={{ column3: 'Item Alat & Mesin', column5: 'Harga Satuan' }}
        />
      )
    },
    {
      tab: 'subcont',
      label: 'Subcont',
      component: () => (
        <TableAnalisa
          type={TYPE}
          id_buaso={'4'}
          data={dataAnalisa}
          setData={setDataAnalisa}
          dataSelect={dataSelectSubkon}
          tableHead={{ column3: 'Item Subcont', column5: 'Harga Satuan' }}
        />
      )
    },
    {
      tab: 'overhead',
      label: 'Overhead',
      component: () => (
        <TableAnalisa
          type={TYPE}
          tab={'OVERHEAD'}
          id_buaso={'5'}
          data={dataAnalisa}
          setData={setDataAnalisa}
          dataSelect={dataSelectOverhead}
          tableHead={{ column3: 'Item Overhead', column5: 'Harga Satuan' }}
        />
      )
    }
  ]

  const grandTotal = () => {
    const getSubtotal = (data) => data?.reduce((acc, { harga, qty }) => {
      const subtotal = Math.round(parseFloat(qty ?? 0) * parseFloat(harga ?? 0))
      return acc + subtotal
    }, 0)

    const totalBahan = getSubtotal(dataAnalisa?.filter(item => item.id_buaso === '1'))
    const totalUpah = getSubtotal(dataAnalisa?.filter(item => item.id_buaso === '2'))
    const totalAlatMesin = getSubtotal(dataAnalisa?.filter(item => item.id_buaso === '3'))
    const totalSubkon = getSubtotal(dataAnalisa?.filter(item => item.id_buaso === '4'))
    const totalOverhead = getSubtotal(dataAnalisa?.filter(item => item.id_buaso === '5' && item.id_kelompok !== '13'))

    const grandTotal = totalBahan + totalUpah + totalAlatMesin + totalSubkon + totalOverhead

    return {
      totalBahan,
      totalUpah,
      totalAlatMesin,
      totalSubkon,
      totalOverhead,
      grandTotal
    }
  }

  const onTabsChangeHandler = (e, newPage) => {
    e.preventDefault();
    setTableTabs(newPage);
  };

  const onSalinAnalisaChange = (val) => {
    if (Boolean(val.value)) {
      RegistrasiBarangJadiApi.getAnalisa({ id_item_buaso: val.value })
        .then(({ data }) => setDataAnalisa(data?.data?.analisa_barang_jadi ?? []))
        .catch(err => {
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data Analisa Barang Jadi tidak ditemukan!'
          })
          setDataAnalisa([])
        })
        .finally(() => setSelectedSalinData(val))
    } else {
      setSelectedSalinData(val)
      setDataAnalisa([])
    }
  }

  useEffect(() => {
    console.log('dataAnalisa', dataAnalisa)
    return () => { };
  }, [dataAnalisa]);


  return (
    <>
      <Row className='d-flex justify-content-between align-items-end mt-4'>
        <Col>
          <h6 className='font-weight-bold'>Data Analisa Barang Jadi</h6>
        </Col>

        <Col>
          {TYPE !== 'DETAIL' && <SelectSearch
            label="Salin data Analisa Barang Jadi"
            placeholder="Pilih untuk salin data Analisa Barang Jadi"
            onChange={(val) => onSalinAnalisaChange(val)}
            option={dropdown?.analisa_barang_jadi ? [{ value: '', label: 'Tanpa Salinan' }, ...dropdown?.analisa_barang_jadi] : []}
            defaultValue={selectedSalinData ?? ''} />}
        </Col>
      </Row>
      <Card className="pb-3 mt-1">
        <Card.Header>
          {/* Tab Header */}
          <Nav variant="tabs" defaultActiveKey={`#${tableTabs}`}>
            {tabsConfig.map((val, index) => (
              <Nav.Item key={`${index}-${val.label}`}>
                <Nav.Link href={`#${val.tab}`} onClick={(e) => onTabsChangeHandler(e, val.tab)}>
                  {val.label}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>

        <Card.Body style={{ minHeight: 350 }}>
          {tabsConfig.map(({ tab, component: Component }, index) => tab === tableTabs && <Component key={index} />)}
        </Card.Body>
      </Card>

      <h6 className='mt-4 font-weight-bold'>Summary Biaya</h6>
      <Card>
        <Card.Body>
          <TableSummary grandTotal={grandTotal} />
        </Card.Body>
      </Card>
    </>
  )
}

export default TableSection
