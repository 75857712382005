import React, {
    useState,
    useEffect
} from 'react'
import {
    useHistory,
    useParams,
    useLocation
} from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
    Card,
    Row,
    Col,
    Nav,
    Modal,
    ModalBody
} from 'react-bootstrap'
import {
    IoAlertCircleOutline
} from 'react-icons/io5'
import {
    Alert,
    BackButton,
    DataStatus,
    ActionButton,
    DatePicker
} from 'components'
import {
    DateConvert
} from 'utilities'
import {
    TabBahan,
    TabUpah,
    TabAlatMesin,
    TabSubcont,
    TabOverhead,
    TabSisaProduksi
} from './Tabs'
import {
    CloseJobMixApi
} from 'api'

const AddCloseJobMix = ({ setNavbarTitle }) => {
    const history = useHistory()
    const location = useLocation()
    const { header } = location.state || {} // MENYIMPAN DATA ITEM DARI GET PAGE UNTUK INFO SECTION
    const { id } = useParams()
    const [listBDP, setListBDP] = useState({})
    const [modalConfirmConfig, setModalConfirmConfig] = useState({
        show: false,
        data: {}
    })
    const [alertConfig, setAlertConfig] = useState({
        show: false,
        variant: 'primary',
        text: ''
    })
    const [fetchingStatus, setFetchingStatus] = useState({
        loading: true,
        success: false
    })

    // Menangani fetching data ketika halaman di render
    const getInitialData = () => {
        setFetchingStatus({
            loading: true,
            success: false
        })

        CloseJobMixApi.getListBDP({
            id_jobmix: id,
            no_jobmix: header.no_jobmix
        })
            .then((listBDP) => {
                setListBDP(listBDP?.data?.data ?? {})
                setFetchingStatus({
                    loading: false,
                    success: true
                })
            })
            .catch((e) => setFetchingStatus({
                loading: false,
                success: false
            }))
    }

    const formInitialValues = {
        id_jobmix: id,
        tgl_close_jobmix: '',
        no_close_jobmix: '',
        qty_close_jobmix: listBDP.qty_jobmix_done ?? 0,
    }

    const formValidationSchema = Yup.object().shape({
        tgl_close_jobmix: Yup.string().required('Pilih tanggal Close Job Mix'),
        no_close_jobmix: Yup.string().required('Pilih tgl. Close Job Mix untuk menentukan nomor'),
        qty_close_jobmix: Yup.string().required('Masukkan Qty. Close Job Mix'),
    })

    const formSubmitHandler = (values, { setSubmitting }) => {
        const finalValues = {
            id_jobmix: values.id_jobmix,
            status_jobmix: 'close',
            tgl_jobmix: values.tgl_close_jobmix,
            qty_close_jobmix: values.qty_close_jobmix
        }

        setModalConfirmConfig({
            show: true,
            data: finalValues
        })
    }

    useEffect(() => {
        setNavbarTitle('Close Job Mix')
        header?.no_jobmix ? getInitialData() : setFetchingStatus({ loading: false, success: false })

        return () => { }
    }, [])

    const Input = ({
        label,
        type,
        name,
        placeholder,
        value,
        readOnly,
        error,
        errorText,
        onChange,
        onBlur,
        onFocus,
        autoComplete,
        maxLength,
        className,
        classNameInput,
        style
    }) => {
        return (
            <div className={`mb-2 ${className}`} style={style}>
                <small>{label}</small>
                <input
                    type={type}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    className={`form-control form-control-sm ${error && "is-invalid"} ${classNameInput}`}
                    onChange={onChange}
                    readOnly={readOnly}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    autoComplete={autoComplete}
                    maxLength={maxLength}
                />
                <div className="invalid-feedback">{errorText}</div>
            </div>
        );
    };

    const InfoSection = () => {
        const InfoItems = ({ label, value }) => (
            <tr style={{ fontSize: 14 }}>
                <td>{label}</td>
                <td className="pl-3 pr-2">:</td>
                <td>{value}</td>
            </tr>
        )

        return (
            <Row>
                <Col md>
                    <table>
                        <tbody>
                            <InfoItems label="Tgl. Job Mix" value={header?.tgl_jobmix ? DateConvert(new Date(header.tgl_jobmix)).detail : '-'} />
                            <InfoItems label="No. Job Mix" value={header?.no_jobmix ?? '-'} />
                        </tbody>
                    </table>
                </Col>
                <Col md>
                    <table>
                        <tbody>
                            <InfoItems label="Customer" value={header?.nama_customer ?? '-'} />
                            <InfoItems label="Barang Jadi" value={header?.nama_item ?? '-'} />
                        </tbody>
                    </table>
                </Col>
            </Row>
        )
    }

    const TableSection = () => {
        const [tabs, setTabs] = useState('bahan') //State untuk menampung tabs yang aktif

        // Konfigurasi komponen tabs
        const tabsConfig = [
            {
                tab: 'bahan',
                label: 'Bahan',
                component: () => <TabBahan dataBahan={listBDP?.bahan ?? []} />
            },
            {
                tab: 'upah',
                label: 'Upah',
                component: () => <TabUpah dataUpah={listBDP?.upah ?? []} />
            },
            {
                tab: 'alatMesin',
                label: 'Alat & Mesin',
                component: () => <TabAlatMesin dataAlatMesin={listBDP?.alat_mesin ?? []} />
            },
            {
                tab: 'subcont',
                label: 'Subcont',
                component: () => <TabSubcont dataSubcont={listBDP?.subkon ?? []} />
            },
            {
                tab: 'overhead',
                label: 'Overhead',
                component: () => <TabOverhead dataOverhead={listBDP?.overhead ?? []} />
            },
            // {
            //     tab: 'sisaProduksi',
            //     label: 'Sisa Produksi',
            //     component: () => <TabSisaProduksi dataSisaProduksi={listBDP?.sisa_produksi ?? []} />
            // },
        ]

        // Menangani perubahan pada tabs
        const onTabsChangeHandler = (e, newPage) => {
            e.preventDefault()
            setTabs(newPage)
        }

        useEffect(() => {
            setTabs(tabsConfig[0].tab)
        }, [])

        const TabsNav = ({ tab, label }) => (
            <Nav.Item>
                <Nav.Link
                    href={`#${tab}`}
                    onClick={e => onTabsChangeHandler(e, tab)}
                >
                    {label}
                </Nav.Link>
            </Nav.Item>
        )

        return (
            <Card>
                <Card.Header>
                    <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
                        {tabsConfig.map((val, index) => <TabsNav key={index} tab={val.tab} label={val.label} />)}
                    </Nav>
                </Card.Header>
                <Card.Body>
                    {tabsConfig.map(({ tab, component: Component }, index) => tab === tabs && <Component key={index} />)}
                </Card.Body>
            </Card>
        )
    }

    const FormSection = ({ formik }) => {
        const { values, touched, errors, isSubmitting, setValues, setFieldValue, handleChange, handleSubmit } = formik
        // Decimal Convert
        const decimalConvert = value => {
            const newValue = value.toString().replace(/[^0-9\.]/g, "")
            const convert = newValue.substring(0, 5)

            return convert
        }

        // Generate nomor ke server
        const getNoHandler = (tanggal = DateConvert(new Date()).default) => {
            CloseJobMixApi.getNo({ tanggal }).then((res) => {
                setValues({
                    ...values,
                    tgl_close_jobmix: tanggal,
                    no_close_jobmix: res.data.data
                })
            })
        }

        useEffect(() => {
            getNoHandler()
            return () => { }
        }, [])

        return (
            <form onSubmit={handleSubmit}>
                <InfoSection />
                <hr />
                <Row className='mb-2'>
                    <Col>
                        <DatePicker
                            label="Tgl. Close Job Mix"
                            selected={values.tgl_close_jobmix ? new Date(values.tgl_close_jobmix) : ''}
                            onChange={date => {
                                const newDate = DateConvert(date).default
                                getNoHandler(newDate)
                            }}
                            error={Boolean(errors.tgl_close_jobmix) && touched.tgl_close_jobmix}
                            errorText={errors.tgl_close_jobmix}
                        />
                    </Col>
                    <Col>
                        <Input
                            readOnly
                            label="No. Close Job Mix"
                            placeholder="Pilih tgl. Close Job Mix"
                            value={values.no_close_jobmix}
                            error={Boolean(errors.no_close_jobmix) && touched.no_close_jobmix}
                            errorText={errors.no_close_jobmix}
                        />
                    </Col>
                    <Col>
                        <Input
                            readOnly
                            label="Qty. Job Mix"
                            value={`${parseFloat(header?.qty_jobmix ?? 0).toPrecision()} ${header?.kode_satuan ?? ''}`}
                            classNameInput="text-right"
                            // onChange={(val) => {
                            //     const qty = decimalConvert(val.target.value)
                            //     setFieldValue('qty_close_jobmix', qty)
                            // }}
                            error={Boolean(errors.qty_close_jobmix) && touched.qty_close_jobmix}
                            errorText={errors.qty_close_jobmix}
                        />

                    </Col>
                </Row>
                <TableSection />
                <div className="mt-2 d-flex justify-content-end">
                    <ActionButton
                        type='submit'
                        variant="danger"
                        text="Close Job Mix"
                        className="mt-3 px-4"
                    />
                </div>
            </form>)
    }

    const ModalConfirm = () => {
        const [isSubmitting, setIsSubmitting] = useState(false)

        const onSubmitHandler = () => {
            setIsSubmitting(true)

            CloseJobMixApi.save(modalConfirmConfig.data)
                .then(() => {
                    history.push('/transaksi/close-job-mix/', {
                        alert: {
                            show: true,
                            variant: 'primary',
                            text: 'Data berhasil ditambah!'
                        }
                    })
                    setModalConfirmConfig({
                        show: false,
                        data: {}
                    })
                })
                .catch(() => {
                    setAlertConfig({
                        show: true,
                        variant: 'danger',
                        text: 'Data gagal ditambah!'
                    })
                    setIsSubmitting(false)
                })
        }

        return (
            <Modal
                closeButton
                show={modalConfirmConfig.show}
                onHide={() => {
                    setModalConfirmConfig({
                        show: false,
                        data: {}
                    })
                }}
                title="Konfirmasi Close Job Mix"
            >
                <ModalBody className="text-center">
                    <IoAlertCircleOutline
                        size={80}
                        className="text-danger mb-3"
                    />
                    <p>
                        <b>Apa Anda yakin akan meng-Close Job Mix ini?</b><br />
                        <small className="text-danger">
                            Job Mix yang telah di CLOSE tidak bisa dikembalikan menjadi OPEN!
                        </small>
                    </p>
                    <div className="d-flex justify-content-center">
                        <ActionButton
                            variant="outline-secondary"
                            text="Batal"
                            className="m-1 px-4"
                            onClick={() => {
                                setModalConfirmConfig({
                                    show: false,
                                    data: {}
                                })
                            }}
                        />
                        <ActionButton
                            variant="danger"
                            text="Close Job Mix"
                            className="m-1 px-4"
                            loading={isSubmitting}
                            onClick={onSubmitHandler}
                        />
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    return (
        <>
            <Alert
                show={alertConfig.show}
                variant={alertConfig.variant}
                text={alertConfig.text}
                showCloseButton={true}
                onClose={() => setAlertConfig({
                    ...alertConfig,
                    show: false
                })}
            />
            <Card className="mb-4">
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <b>Close Job Mix</b>
                    <BackButton onClick={() => history.goBack()} />
                </Card.Header>
                <Card.Body>
                    {
                        fetchingStatus.loading
                            ? <DataStatus loading={true} text="Memuat data . . ." />
                            : fetchingStatus.success
                                ? <Formik
                                    initialValues={formInitialValues}
                                    validationSchema={formValidationSchema}
                                    onSubmit={formSubmitHandler}
                                >
                                    {(formik) => <FormSection formik={formik} />}
                                </Formik>
                                : <DataStatus text="Data gagal dimuat!" />
                    }
                </Card.Body>
            </Card>
            {modalConfirmConfig.show && <ModalConfirm />}
        </>
    )
}

export default AddCloseJobMix;
