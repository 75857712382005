import {
  IoCashOutline,
  IoSpeedometerOutline,
  IoServerOutline,
  IoDocumentTextOutline,
} from "react-icons/io5";

/* 
  MENU REQUIREMENTS
    > TEXT  = REQUIRED
    > LINK  = REQUIRED
    > EXACT = OPTIONAL (TRUE/FALSE)
    > TYPE  = REQUIRED (MENU/DROPDOWN)
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
    > ICON  = OPTIONAL (REACT-ICONS)
*/

export default [
  // Dashboard
  {
    text: "Dashboard",
    link: "/",
    type: "menu",
    exact: true,
    icon: <IoSpeedometerOutline />,
    hak: ["SUPA", "PRD"],
  },
  // Master
  {
    text: "Master Data",
    type: "dropdown",
    icon: <IoServerOutline />,
    hak: ["SUPA", "PRD"],
    menu: [
      {
        text: "Jenis Barang Jadi",
        link: "/master/jenis-barang-jadi",
        hak: ["SUPA", "PRD_MAS_JBJ"],
      },
      {
        text: "Barang Jadi",
        link: "/master/registrasi-barang-jadi",
        hak: ["SUPA", "PRD_MAS_BRJ"],
      },
      {
        text: "Pabrik",
        link: "/master/pabrik",
        hak: ["SUPA", "PRD_MAS_PAB"],
      },
    ],
  },
  // Transaksi
  {
    text: "Transaksi",
    type: "dropdown",
    icon: <IoCashOutline />,
    hak: ["SUPA", "PRD"],
    menu: [
      {
        text: "Job Mix",
        link: "/transaksi/job-mix",
        hak: ["SUPA", "PRD_TRN_JBX"],
      },
      {
        text: "Realisasi Job Mix",
        link: "/transaksi/realisasi-job-mix",
        hak: ["SUPA", "PRD_TRN_REP"],
      },
      {
        text: "Close Job Mix",
        link: "/transaksi/close-job-mix",
        hak: ["SUPA", "PRD_TRN_CLJ"],
      },
    ],
  },
  {
    text: "Laporan",
    type: "dropdown",
    icon: <IoDocumentTextOutline />,
    hak: ["SUPA", "PRD"],
    menu: [],
  },

  // {
  //   text: "Profil",
  //   type: "dropdown",
  //   icon: <IoPersonCircleOutline />,
  //   hak: ["SUPA", "PRD"],
  //   menu: [
  //     {
  //       text: "Akun Saya",
  //       link: "/profil",
  //       hak: ["SUPA", "PRD"],
  //     },
  //     {
  //       text: "Ubah Akun",
  //       link: "/profil/ubah",
  //       hak: ["SUPA", "PRD"],
  //     },
  //     {
  //       text: "Ganti Password",
  //       link: "/profil/ganti-password",
  //       hak: ["SUPA", "PRD"],
  //     },
  //   ],
  // },
];
