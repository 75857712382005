import Services from 'services'

class JobMix {
  get(params) {
    return Services.get('/jobmix/page', {params})
  }

  getSalesOrder(params) {
    return Services.get('/jobmix/sales_order', {params})
  }

  getSingle(params) {
    return Services.get('/jobmix/single', {params})
  }

  getNomor(params) {
    return Services.get('/jobmix/no_baru', {params})
  }

  getPabrik(params) {
    return Services.get('/jobmix/card_pabrik', {params})
  }

  getDetailGudang(params) {
    return Services.get('/jobmix/data_gudang', {params})
  }

  getStokBarangGudang(params) {
    return Services.get('/jobmix/isi_pabrik', {params})
  }

  save(data) {
    return Services.post('/jobmix', data)
  }
}

export default new JobMix();