import React, {
  useState,
  useEffect
} from 'react'
import {
  useHistory,
  useLocation
} from 'react-router-dom'
import {
  Row,
  Col
} from 'react-bootstrap'
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  CreateButton
} from 'components'
import {
  DateConvert,
  PageNumber as TableNumber,
} from 'utilities'
import {
  RealisasiJobMixApi
} from 'api'


const ListRealisasiJobMix = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataRealisasiProduksi, setDataRealisasiProduksi] = useState([])
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0'
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: ''
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: ''
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    RealisasiJobMixApi.get({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength
    })
      .then(({ data }) => {
        setDataRealisasiProduksi(data.data)
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page
        })
      })
      .catch(err => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!'
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant
        })
      }
    }
  }

  const onInputSearchChange = e => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value
    })
    setPaginationConfig({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0'
    })
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key
    })
  }

  useEffect(() => {
    setNavbarTitle('List Realisasi Job Mix')
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }

    // eslint-disable-next-line
  }, [setNavbarTitle, searchConfig.key, paginationConfig.page, paginationConfig.dataLength])

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Tgl. Realisasi Job Mix</ThFixed>
            <ThFixed>No. Realisasi Job Mix</ThFixed>
            <ThFixed>No. Job Mix</ThFixed>
            <Th>Barang Jadi</Th>
            <Th>Qty. Realisasi Job Mix</Th>
          </Tr>
        </THead>
        <TBody>
          {dataRealisasiProduksi.map((val, index) => (
            <Tr key={index}>
              <TdFixed className="text-center">{TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}</TdFixed>
              <TdFixed>
                <ReadButton
                  size="sm"
                  onClick={() => history.push(`/transaksi/realisasi-job-mix/detail/${val.id_jobmix_realisasi}`)}
                />
              </TdFixed>
              <TdFixed>{val.tgl_jobmix_realisasi ? DateConvert(new Date(val.tgl_jobmix_realisasi)).defaultDMY : '-'}</TdFixed>
              <TdFixed>{val.no_jobmix_realisasi ? val.no_jobmix_realisasi : '-'}</TdFixed>
              <TdFixed>{val.no_jobmix ? val.no_jobmix : '-'}</TdFixed>
              <Td>{val.nama_barang ? val.nama_barang : '-'}</Td>
              <Td className="text-right">{val.qty_jobmix_realisasi} {val.kode_satuan}</Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    )

    if (!dataRealisasiProduksi || dataRealisasiProduksi.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status &&
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1}
            dataPage={paginationConfig.dataCount < paginationConfig.dataLength ? paginationConfig.dataCount : paginationConfig.page * paginationConfig.dataLength}
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) => setPaginationConfig({
              ...paginationConfig,
              page: selected + 1
            })}
            onDataLengthChange={(e) => setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value
            })}
          />
        }
      </>
    )
  }

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch
                value={searchConfig.key}
                onChange={onInputSearchChange}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => history.push('/transaksi/realisasi-job-mix/list-job-mix')} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({
          ...alertConfig,
          show: false
        })}
      />

      {/* content */}
      {isPageLoading
        ? <DataStatus
          loading={true}
          text="Memuat data . . ."
        />
        : <PageContent />
      }
    </CRUDLayout>
  )
}

export default ListRealisasiJobMix