import {
  useState,
  useEffect
} from 'react'
import { 
  ModalBody, 
  ModalFooter,
  Row,
  Col
} from 'react-bootstrap'
import { 
  IoAddOutline 
} from 'react-icons/io5'
import {
  Formik
} from 'formik'
import * as Yup from 'yup'
import {
  DataStatus,
  Modal,
  ActionButton,
  Table, 
  Td, 
  TdFixed, 
  Th,
  ThFixed,
  InfoItemHorizontal,
  DatePicker,
  Input,
  PopUpAlert
} from 'components'
import {
  DateConvert,
  RupiahConvert
} from 'utilities'

const TabUpahSection = ({selectedPabrik, dataUpah, dataPermintaanUpah, setDataPermintaanUpah}) => {
  const [modalPermintaanConfig, setModalPermintaanConfig] = useState({
    show: false,
    data: {}
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    type: '',
    title: '',
    text: '',
    hide: () => setAlertConfig({...alertConfig, show: false})
  })
  
  const TableIngredient = () => {
    const getTotal = (qty, harga) => {
      const parseQty = qty ? parseFloat(qty) : 0
      const parseHarga = harga ? parseInt(harga) : 0

      return parseInt(parseHarga * parseQty)
    }

    const getGrandTotal = () => dataUpah.reduce((prev, current) => {
      const total = getTotal(current.qty_analisa, current.harga)

      return prev + total
    }, 0)

    return (
      <div>
        <div className="px-1 pb-2">
          <b>List Ingredient</b>
        </div>
        <Table responsive>
          <thead className="bg-light">
            <tr>
              <ThFixed>No.</ThFixed>
              <Th noPadding width={50}>Kode Upah</Th>
              <Th>Item Upah</Th>
              <Th noPadding width={50}>Qty. Analisa</Th>
              <Th noPadding width={150}>Harga Satuan</Th>
              <Th noPadding width={150}>Sub Total</Th>
            </tr>
          </thead>
          <tbody>
            {dataUpah && dataUpah.length > 0
              ? dataUpah.map((val, index) => (
                <tr key={index}>
                  <Td textCenter className="pb-2">{index + 1}</Td>
                  <Td>{val.kode_item}</Td>
                  <Td>{val.nama_item}</Td>
                  <Td textRight>{val.qty_analisa} {val.kode_satuan}</Td>
                  <Td textRight>{RupiahConvert(String(val.harga)).detail}</Td>
                  <Td textRight>{RupiahConvert(String(getTotal(val.qty_analisa, val.harga))).detail}</Td>
                </tr>
              ))
              : <tr>
                  <Td colSpan={6}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </tr>
            }
          </tbody>
          <tfoot>
            <tr className="bg-light">
              <Td textRight colSpan={5}>
                <b style={{fontSize: 14}}>Total</b>
              </Td>
              <Td textRight>
                <b style={{fontSize: 14}}>
                  {RupiahConvert(getGrandTotal().toString()).detail}
                </b>
              </Td>
            </tr>
          </tfoot>
        </Table>
      </div>
    )
  }

  const ModalPermintaan = () => {
    const dataInfo = modalPermintaanConfig.data

    const modalFormInitialValues = {
      tanggal: '',
      qty: dataInfo.qty_analisa ? parseFloat(dataInfo.qty_analisa) : 0,
      satuan: dataInfo.satuan
    }
    
    const modalFormValidationSchema = Yup.object().shape({
      tanggal: Yup.string()
        .required('Pilih tanggal'),
      qty: Yup.number()
        .required('Masukan qty')
        .positive('Qty harus lebih besar daripada 0')
        .typeError('Qty harus berupa angka')
        .test({
          test: qty => qty >= dataInfo.qty_analisa,
          message: 'Qty. permintaan harus lebih besar atau sama dari qty. analisa'
        })
    })

    const modalFormSubmitHandler = values => {
      const savePermintaan = () => new Promise(resolve => setTimeout(() => resolve('berhasil'), 100))

      savePermintaan()
        .then(() => {
          const dataPermintaan = {
            id_item: dataInfo.id_item,
            id_buaso: dataInfo.id_buaso,
            tgl_permintaan: values.tanggal,
            kode_item: dataInfo.kode_item,
            nama_item: dataInfo.nama_item,
            qty_permintaan: values.qty
          }
          setModalPermintaanConfig({
            show: false,
            data: {}
          })
          setAlertConfig({
            ...alertConfig,
            show: true,
            type: 'success',
            title: 'Pengajuan Permintaan Berhasil',
            text: "Permintaan berhasil diajukan!",
            hide: () => {
              setAlertConfig({
                ...alertConfig,
                show: false
              })
              setDataPermintaanUpah([
                ...dataPermintaanUpah,
                dataPermintaan
              ])
            }
          })
        })
        .catch(() => {
          setModalPermintaanConfig({
            show: false,
            data: {}
          })
          setAlertConfig({
            ...alertConfig,
            show: true,
            type: 'danger',
            title: 'Pengajuan Permintaan Gagal',
            text: "Permintaan gagal diajukan!",
            hide: () => {
              setAlertConfig({
                ...alertConfig,
                show: false
              })
            }
          })
        })
    }

    const ModalInfoSection = () => {
      return (
        <div>
          <InfoItemHorizontal 
            label="Pabrik"
            text={selectedPabrik.nama_pabrik}
            width={120}
          />
          <InfoItemHorizontal 
            label="Kode Upah"
            text={dataInfo.kode_item}
            width={120}
          />
          <InfoItemHorizontal 
            label="Item Upah"
            text={dataInfo.nama_item}
            width={120}
          />
          <InfoItemHorizontal 
            label="Qty. Analisa"
            text={dataInfo.qty_analisa}
            width={120}
          />
        </div>
      )
    }

    const ModalFormSection = ({formik}) => {
      const today = DateConvert(new Date()).default
      const {values, errors, touched, setValues, handleChange} = formik

      const getNomorHandler = date => {
        setValues({
          ...values,
          tanggal: date,
        })
      }

      useEffect(() => {
        getNomorHandler(today)
      }, [])

      return (
        <>
          <Row>
            <Col sm>
              <DatePicker 
                label="Tanggal Job Mix"
                placeholderText={'Pilih tanggal'}
                selected={values.tanggal ? new Date(values.tanggal) : ''}
                onChange={date => getNomorHandler(DateConvert(date).default)}
                error={Boolean(errors.tanggal && touched.tanggal)}
                errorText={Boolean(errors.tanggal && touched.tanggal) && errors.tanggal}
              />
            </Col>
          </Row>
          <Row>
            <Col sm>
              <Input 
                type="number"
                label="Qty. Permintaan"
                name="qty"
                placeholder="Masukan qty. permintaan"
                value={values.qty}
                onChange={handleChange}
                error={Boolean(errors.qty && touched.qty)}
                errorText={Boolean(errors.qty && touched.qty) && errors.qty}
              />
            </Col>
            <Col sm>
              <Input 
                readOnly
                label="Satuan"
                placeholder="Masukan satuan"
                value={dataInfo.satuan}
              />
            </Col>
          </Row>
        </>
      )
    }

    return (
      <Modal
        closeButton
        title="Permintaan Job Mix"
        show={modalPermintaanConfig.show}
        onHide={() => setModalPermintaanConfig({
          show: false,
          data: {}
        })}
      >
        <Formik
          initialValues={modalFormInitialValues}
          validationSchema={modalFormValidationSchema}
          onSubmit={modalFormSubmitHandler}
        >
          {formik => (
            <>
              <ModalBody>
                <ModalInfoSection />
                <hr />
                <ModalFormSection formik={formik} />
              </ModalBody>
              <ModalFooter>
                <ActionButton 
                  text="Buat Permintaan"
                  onClick={formik.handleSubmit}
                  loading={formik.isSubmitting}
                />
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    )
  }

  return (
    <>  
      <TableIngredient />
      <PopUpAlert 
        show={alertConfig.show}
        onConfirm={alertConfig.hide}
        onHide={alertConfig.hide}
        title={alertConfig.title}
        text={alertConfig.text}
        type={alertConfig.type}
      />
      {modalPermintaanConfig.show && <ModalPermintaan />}
    </>
  )
}

export default TabUpahSection
